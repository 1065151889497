import React, { useEffect, useRef, useMemo } from "react";
import styled from "styled-components/macro";

import { useFlowGraphStoreMethods, useParameter, useParameterDisabledCheck } from "@mirinae/hyperflow/modules/stores/flowgraph";
import { PromptEditor, PromptWrapper } from "./Prompt";
import UI from "@mirinae/hyperflow/components/ui/widgets";
import { useViewerStoreMethods } from "@mirinae/hyperflow/modules/stores/viewer";
import { InstructionsValue, PromptValue } from "@mirinae/classes/DataValue";

const Instructions = ({ flow, step, mode, paramUI, setLocked, nodeFirstRun }) => {
    const { setFocusedNode } = useViewerStoreMethods();
    const parameter = useParameter(step, paramUI.pathName);
    const disabled = useParameterDisabledCheck(paramUI);

    const { setParameter, addStepDisplay, closeParamUI } = useFlowGraphStoreMethods();
    const editorRef = useRef();

    // we do want prompts to clear afer first use, but not instructions, typically we want to make small tweaks to the prior
    // instruciton if unlocked!!
    const text = /* (paramUI.locked || mode === "configuring" || nodeFirstRun) && */ parameter?.value || "";

    const sendText = e => {
        setParameter(paramUI.pathName, new InstructionsValue(editorRef.current.value, paramUI.locked));
        addStepDisplay({ instructionsText: editorRef.current.value });
        if (flow.runState.startsWith("configuring")) setFocusedNode(null);
        if (paramUI.locked) {
            closeParamUI(paramUI, true);
        }
    };

    const onBlur = e => {
        if (editorRef.current && mode !== "historical" && editorRef.current.value !== parameter?.value) {
            setParameter(paramUI.pathName, new PromptValue(editorRef.current.value, paramUI.locked));
        }
    };

    useEffect(() => {
        const keyDown = e => {
            if (mode !== "historical" && !paramUI.closed && e.metaKey && e.key === "Enter") {
                sendText();
                e.preventDefault();
                e.stopPropagation();
            }
        };
        document.addEventListener("keydown", keyDown);
        return () => document.removeEventListener("keydown", keyDown);
    }, [paramUI, mode]);

    const lockClicked = () => {
        const newLockedState = !paramUI.locked;
        if (newLockedState) {
            if (editorRef.current.value) {
                setParameter(paramUI.pathName, new InstructionsValue(editorRef.current.value, paramUI.locked));
                addStepDisplay({ instructionsText: editorRef.current.value });
                setLocked(newLockedState);
            }
        } else {
            setLocked(newLockedState);
        }
    };

    return (
        <UI.FieldBox
            title={paramUI.label || "Instructions"}
            setLocked={setLocked}
            fieldStyle={{ textAlign: "left", whiteSpace: "pre-wrap", color: "#767575", maxHeight: 250 }}
            popupEditor
            locked={paramUI.locked}
        >
            {paramUI.closed || mode === "historical" ? (
                parameter.displayValue()
            ) : (
                <PromptEditor
                    className="instructionEditor"
                    ref={editorRef}
                    defaultValue={text}
                    disabled={disabled}
                    minRows={10}
                    onBlur={onBlur}
                    autoFocus
                />
            )}
        </UI.FieldBox>
    );
};

const InstructionsWrapper = styled(PromptWrapper)`
    ::before {
        display: inline-block;
        position: absolute;
        left: 4px;
        top: 2px;
        content: url("/assets/images/instructions.svg");
        transform: scale(0.9);
    }
`;

// const InstructionsLock = styled(FBLock)``;

const SetInstructions = styled.div``;

const AcceptButton = styled.div`
    position: absolute;
    right: 10px;
    bottom: -24px;
    padding: 3px 5px;
    border: thin solid lightgray;
    border-radius: 10px;
    cursor: pointer;
    z-index: 10;

    :hover {
        box-shadow:
            0 3px 1px -2px rgb(0 0 0 / 20%),
            0 2px 2px 0 rgb(0 0 0 / 14%),
            0 1px 5px 0 rgb(0 0 0 / 12%);
    }
`;

export default Instructions;
