import React, { useEffect, useRef, useState, useMemo } from "react";
import styled from "styled-components/macro";
import TextareaAutosize from "react-textarea-autosize";
import { Popover } from "antd";
import { useImmer } from "use-immer";

import { useFlowGraphStoreMethods, useParameter } from "@mirinae/hyperflow/modules/stores/flowgraph";
import UI from "@mirinae/hyperflow/components/ui/widgets";
import DirectoryTree from "@mirinae/hyperflow/components/ui/DirectoryTree";
import { useDirectorySelectorStore, useDirectorySelectorStoreMethods } from "@mirinae/hyperflow/modules/stores/directory";
import { DirectorySelectValue, DataValue, UploadsValue } from "@mirinae/classes/DataValue";
import { DirectoryNode } from "@hyperflow/modules/classes/DirectoryNodes";
import { useTagTreeStore } from "@hyperflow/modules/stores/tagTree";
import { localDate, utcDate } from "@mirinae/shared/modules/utils/formatters";

const DirectorySelect = ({ mode, step, paramUI, setLocked, nodeFirstRun }) => {
    const { getCheckedSelection, setNodeMap } = useDirectorySelectorStoreMethods();
    const nodeMap = useDirectorySelectorStore(state => state.nodeMap);
    const parameter = useParameter(step, paramUI.pathName);
    const reloadTrigger = useDirectorySelectorStore(state => state.reloadTrigger);
    const { setParameter, closeParamUI } = useFlowGraphStoreMethods();
    const [searchString, setSearchString] = useState("");
    const [selectionDisplay, setSelectionDisplay] = useState(/*paramUI.closed ? */ parameter?.displayValue() /* : null */);
    const searchBoxRef = useRef();

    // useEffect(() => {
    //     httpAPI('', apiPaths.getTagTree, { data: { } })
    //         .then(response => {
    //             const { data: { nodeMap, rootNodeID } } = response;
    //             setNodeMap(nodeMap, rootNodeID);
    //         });
    // }, [reloadTrigger]);

    const value = useMemo(() => parameter?.value || paramUI.defaultValue?.value, [parameter]);

    const okEnabled = useMemo(() => nodeMap && Object.values(nodeMap).some(n => n.checked), [nodeMap]);
    const required = useMemo(() => paramUI.awaiting && !parameter?.value, [paramUI, parameter]);

    const searchBoxChange = e => {
        setSearchString(e.target.value);
    };

    const setLockedClicked = lockState => {
        if (!lockState) {
            // unlocking resets selection
            setSelectionDisplay(null);
            setLocked(lockState);
        } else if (selectionDisplay) {
            setLocked(lockState);
        }
    };

    const accept = async () => {
        const selections = okEnabled ? getCheckedSelection() : value;
        if (paramUI.singleSelect) {
            // disable for now, try making singleton selections be the selected value
            // // allow selection specializations to prepare for following parameter processing, augmenting the singleton selection structure,
            // // such as loading selected object's implementing service, or other DB data
            // const selection = DirectoryNode.makeFrom(selections.selection[0]);
            // await selection.directorySelectPostProcessing(selection, parameter, paramUI);

            if (okEnabled) {
                // construct selected object by making empty DataValue & calling inflateFromDirectoryEntry() on it - each selectable PV class must implement
                const selected = selections.selection[0];
                const { type, taggingID, name, objectID } = selected;
                const parameter = await DataValue.makeFrom({
                    type,
                    locked: paramUI.locked,
                    label: name,
                }).inflateFromDirectoryEntry(selected);
                setParameter(paramUI.pathName, parameter);
                setSelectionDisplay(parameter?.displayValue());
            } else {
                // accept on already selected value, already in parameter, set again to resolve any param-request awaits
                setParameter(paramUI.pathName, parameter);
                setSelectionDisplay(parameter?.displayValue());
            }
        } else {
            setParameter(paramUI.pathName, new DirectorySelectValue(selections, paramUI.locked, selections.display));
            setSelectionDisplay(selections.display);
        }
        if (paramUI.locked) {
            closeParamUI(paramUI, true);
        }
    };

    const resetSelection = () => {
        // setParameter(paramUI.pathName, new DirectorySelectValue(undefined, false, ""));
        if (!paramUI.locked) {
            setSelectionDisplay(null);
            closeParamUI(paramUI, false);
        }
    };

    return (
        <UI.FieldBox
            title={paramUI.label}
            setLocked={setLockedClicked}
            locked={paramUI.locked}
            popupEditor={{ maxHeight: "unset" }}
            required={required}
            fieldStyle={{ maxHeight: 400 }}
            accept={!paramUI.closed && value && accept}
        >
            {selectionDisplay ? (
                <DisplaySelection
                    disabled={paramUI.locked || mode === "historical"}
                    locked={paramUI.locked}
                    onClick={() => mode !== "historical" && resetSelection()}
                >
                    <DirectoryPopover title={selectionDisplay} parameter={parameter}>
                        {selectionDisplay}
                    </DirectoryPopover>
                </DisplaySelection>
            ) : (
                <DirectorySearchWrapper>
                    <SearchBox>
                        <span>Search:</span>
                        <SearchTextArea ref={searchBoxRef} minRows={1} autoFocus onChange={searchBoxChange} />
                    </SearchBox>
                    <Treebox>
                        <DirectoryTree
                            useDirectoryStore={useDirectorySelectorStore}
                            filter={paramUI.filter}
                            searchString={searchString}
                            selectMode={paramUI.singleSelect ? "single" : "multiple"}
                            openToLevel={1}
                        />
                    </Treebox>
                    <AcceptButton disabled={!okEnabled} onClick={accept}>
                        OK
                    </AcceptButton>
                </DirectorySearchWrapper>
            )}
        </UI.FieldBox>
    );
};

const DirectorySearchWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 0;
    width: 100%;
    max-height: 350px;

    .editor-popup & {
        max-height: 100%;
    }
`;

const SearchBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
        margin-right: 6px;
        font-size: 11px;
    }
`;

const SearchTextArea = styled(TextareaAutosize)`
    border: none;
    font-family:
        DM Sans,
        serif;
    font-size: 12px;
    // letter-spacing: 0.4px;
    padding: 4px 5px;
    resize: none;
    width: 150px;

    :focus {
        outline: none;
    }
`;

const Treebox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 8px;
    padding: 0 0 8px 8px;
    flex: 1;
    overflow-y: auto;
    border: thin solid lightgray;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px;
`;

const AcceptButton = styled.button`
    padding: 3px 5px;
    border: thin solid #b7b7b7;
    border-radius: 10px;
    width: 40px;
    margin-top: 9px;
    text-align: center;
    align-self: center;
    cursor: pointer;
    z-index: 10;
    margin-bottom: 5px;
    :hover {
        box-shadow:
            0 3px 1px -2px rgb(0 0 0 / 20%),
            0 2px 2px 0 rgb(0 0 0 / 14%),
            0 1px 5px 0 rgb(0 0 0 / 12%);
    }
`;

const DisplaySelection = styled.div`
    padding: 1px;
    cursor: ${props => (props.disabled ? "inherit" : "pointer")};
    user-select: none;
    ${props => !props.locked && !props.disabled && "background: white;"}
`;

export const DirectoryPopover = ({ title, parameter, children }) => {
    const [detailOpen, setDetailOpen] = useImmer({});
    return (
        <Popover
            content={<DirectoryEntryDetail entry={parameter} />}
            title={title}
            placement="right"
            mouseEnterDelay={0.5}
            open={detailOpen["selected"]}
            onOpenChange={open =>
                setDetailOpen(state => {
                    state["selected"] = open;
                })
            }
        >
            <div
                style={{
                    maxWidth: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
            >
                {children}
            </div>
        </Popover>
    );
};

const DirectoryEntryDetail = ({ entry }) => {
    const [contents, setContents] = useState([]);
    const objectMap = useTagTreeStore(state => state.objectMap);
    useEffect(() => {
        if (entry) {
            const entryContents = entry.directoryPopoverContents(objectMap);
            setContents(entryContents);
        }
    }, [entry]);
    return (
        <DetailWrapper>
            {contents.map((c, i) =>
                c.label ? (
                    <Value>
                        <Label>{c.label}: </Label>
                        {c.value}
                    </Value>
                ) : c.tags ? (
                    <Tags>
                        <Label>Tags: </Label>
                        {c.tags}
                    </Tags>
                ) : (
                    c.created && (
                        <Created>
                            <Label>Created (local): </Label>
                            {localDate(c.created)}
                            <Label> (UTC): </Label>
                            {utcDate(c.created)}
                        </Created>
                    )
                )
            )}
        </DetailWrapper>
    );
};

const DetailWrapper = styled.div`
    max-width: 600px;
    width: fit-content;
    p {
        font-size: 97%;
        color: gray;
    }
`;

const Value = styled.div``;
const Created = styled(Value)``;
const Tags = styled(Value)``;
const Label = styled.span`
    font-weight: bold;
    font-size: smaller;
    width: 120px;
`;

export default DirectorySelect;
