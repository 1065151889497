import React, { useRef, useState, useMemo } from "react";
import styled from "styled-components/macro";
import { Dropdown } from "antd";

import {
    useFlowGraphStore,
    useFlowGraphStoreMethods,
    useParameter,
    useParameterDisabledCheck,
} from "@mirinae/hyperflow/modules/stores/flowgraph";
import UI from "@mirinae/hyperflow/components/ui/widgets";
import { ServiceValue } from "@mirinae/classes/DataValue";

const selectIntro = "Select service...";
const clearSelection = [{ type: "divider" }, { label: <span style={{ color: "#7d7d7d" }}>Clear selection</span>, key: selectIntro }];

const SelectService = ({ step, paramUI, setLocked }) => {
    const adapters = useFlowGraphStore(state => state.tools.adapters);
    const { setParameter, findService, clearParameter, closeParamUI } = useFlowGraphStoreMethods();
    const parameter = useParameter(step, paramUI.pathName);
    const disabled = useParameterDisabledCheck(paramUI);

    const [selected, setSelected] = useState(parameter?.value?.displayName || selectIntro);
    const required = useMemo(() => paramUI.awaiting && selected === selectIntro, [paramUI, parameter]);

    const serviceDisplay = useMemo(() => {
        return paramUI.closed && parameter?.value ? findService(parameter.value).displayName : "";
    }, [paramUI, parameter]);

    const items = useMemo(
        () =>
            adapters
                .filter(a => a.serviceType === paramUI.serviceType)
                .map(a => ({
                    label: a.displayName,
                    key: a.serviceFamily,
                    disabled: a.status !== "enabled",
                }))
                .concat(selected !== selectIntro ? clearSelection : []),
        [paramUI, adapters, selected]
    );

    const selectService = ({ item, key, domEvent: e }) => {
        // record selected service
        setSelected(key);
        const value = key === selectIntro ? undefined : key;
        const service = value && adapters.find(a => a.serviceType === paramUI.serviceType && a.serviceFamily === value);
        if (service || value === undefined) {
            setParameter(paramUI.pathName, new ServiceValue(service, paramUI.locked));
            if (paramUI.locked) {
                closeParamUI(paramUI, true);
            }
            paramUI.awaiting = value === undefined;
        }
    };

    return (
        <UI.FieldBox
            title={paramUI.label}
            locked={paramUI.locked}
            setLocked={setLocked}
            required={required}
            accept={!paramUI.closed && parameter?.value && selectService}
        >
            {paramUI.closed ? (
                <ClosedService>
                    {serviceDisplay}
                    {step.paramUILoading && selected !== selectIntro && <ServiceSpinner />}
                </ClosedService>
            ) : (
                <SelectorDropdown
                    menu={{ items, onClick: selectService }}
                    disabled={disabled}
                    trigger={["click"]}
                    autoAdjustOverflow={false}
                    getPopupContainer={te => te.parentNode}
                    overlayStyle={{ background: "white" }}
                >
                    <Selector>
                        <Value>{selected}</Value>{" "}
                        <div style={{ display: "flex", alignItems: "center" }}>
                            {step.paramUILoading && !step.exception && selected !== selectIntro && <ServiceSpinner />}{" "}
                            <img src="/assets/images/dropdown-chevron.svg" alt="menu" />
                        </div>
                    </Selector>
                </SelectorDropdown>
            )}
        </UI.FieldBox>
    );
};

const Selector = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 100%;

    .ant-dropdown-menu {
        background-color: white;
    }
`;

const SelectorDropdown = styled(Dropdown)``;

const Value = styled.div`
    font-size: 13px;
`;

const ServiceSpinner = () => <SSImage src="/assets/images/spinner.png" alt="sp" />;

const SSImage = styled.img`
    width: 14px;
    height: auto;
    animation: rotate-center 1s linear infinite;
    transform-origin: center center;
    margin-right: 3px;

    @keyframes rotate-center {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`;

const ClosedService = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export default SelectService;
