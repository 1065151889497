import React, { useEffect, useRef, useState, useMemo } from "react";
import styled from "styled-components/macro";
import TextareaAutosize from "react-textarea-autosize";
import { Button } from "antd";

import { useFlowGraphStoreMethods, useParameter, useParameterDisabledCheck } from "@mirinae/hyperflow/modules/stores/flowgraph";
import UI from "@mirinae/hyperflow/components/ui/widgets";
import { useViewerStoreMethods } from "@mirinae/hyperflow/modules/stores/viewer";
import { PromptValue } from "@mirinae/classes/DataValue";
import { httpAPI } from "@mirinae/apis/http";
import { apiPaths } from "@mirinae/shared/modules/defines/paths";
import { useChatbotStore } from "@hyperflow/modules/stores/chatbot";

const Prompt = ({ flow, step, mode, paramUI, setLocked, nodeFirstRun }) => {
    const { setFocusedNode } = useViewerStoreMethods();
    const chatbotIsShowing = useChatbotStore(state => state.bot.showChatbot);
    const parameter = useParameter(step, paramUI.pathName);
    const disabled = useParameterDisabledCheck(paramUI);
    const { setParameter, triggerRunButton, closeParamUI, resetHadParameterInput } = useFlowGraphStoreMethods();
    const [promptHistory, setPromptHistory] = useState([]);
    const editorRef = useRef();
    const historyPopupRef = useRef();

    // for now, effectively disable parameter-panel prompt IU if test chatbot is showing
    if (mode === "current" && chatbotIsShowing) resetHadParameterInput();

    const text = ((paramUI.locked || mode === "configuring" || nodeFirstRun || chatbotIsShowing) && parameter?.value) || "";

    useEffect(() => {
        if (editorRef.current && editorRef.current.value !== text) {
            editorRef.current.value = parameter.value;
        }
    }, [text, chatbotIsShowing]);

    const sendText = text => {
        setParameter(paramUI.pathName, new PromptValue(text, paramUI.locked));
        if (editorRef.current) editorRef.current.value = text;
        if (flow.runState.startsWith("configuring")) setFocusedNode(null);
        if (paramUI.locked) {
            closeParamUI(paramUI, true);
        }
    };

    const onBlur = e => {
        if (editorRef.current /* && mode === "configuring" why only configuring? */ && editorRef.current.value !== parameter?.value) {
            setParameter(paramUI.pathName, new PromptValue(editorRef.current.value, paramUI.locked));
        }
    };

    // useEffect(() => {
    //     const keyDown = e => {
    //         if (mode !== "historical" && !paramUI.closed && e.metaKey && e.key === "Enter") {
    //             sendText(editorRef.current.value);
    //             e.preventDefault();
    //             e.stopPropagation();
    //         }
    //     };
    //     document.addEventListener("keydown", keyDown);
    //     return () => document.removeEventListener("keydown", keyDown);
    // }, [paramUI]);

    const promptButtonClicked = pb => {
        sendText(pb.promptText);
        triggerRunButton();
    };

    const showPromptHistory = async () => {
        const response = await httpAPI("", apiPaths.getPromptHistory, {
            data: { skip: 0, limit: 10 },
        });
        setPromptHistory(response.data.prompts);
    };

    const selectPriorPrompt = i => {
        editorRef.current.value = promptHistory[i].value;
        setPromptHistory([]);
    };

    const checkOutsideClick = e => {
        if (e.target === historyPopupRef.current || historyPopupRef.current?.contains(e.target)) return;
        setPromptHistory([]);
    };

    useEffect(() => {
        document.body.addEventListener("click", checkOutsideClick, false);
        return () => {
            document.body.removeEventListener("click", checkOutsideClick);
        };
    }, []);

    return chatbotIsShowing && mode !== "historical" ? (
        <div>Prompt input in test chatbot</div>
    ) : (
        <UI.FieldBox
            title={paramUI.label || "User prompt"}
            setLocked={setLocked}
            locked={paramUI.locked}
            popupEditor
            fieldStyle={{ textAlign: "left", whiteSpace: "pre-wrap" }}
        >
            {paramUI.closed || mode === "historical" ? (
                parameter.displayValue()
            ) : (
                <PromptBox>
                    {paramUI.promptButtons?.length > 0 && (
                        <PromptButtons>
                            <PromptButtonBlock>
                                {paramUI.promptButtons.map((pb, i) => (
                                    <Button key={i} onClick={e => promptButtonClicked(pb)}>
                                        {pb.label}
                                    </Button>
                                ))}
                            </PromptButtonBlock>
                        </PromptButtons>
                    )}
                    {/*<PromptHistoryBtn src="/assets/images/prompt-history-1.svg" onClick={showPromptHistory} />*/}
                    <PromptEditor
                        className="editor"
                        disabled={disabled}
                        defaultValue={text}
                        ref={editorRef}
                        minRows={10}
                        autoFocus
                        onBlur={onBlur}
                        hasPromptButtons={paramUI.promptButtons?.length > 0}
                    />
                    {/*{promptHistory?.length > 0 && (*/}
                    {/*    <PromptHistoryPopup ref={historyPopupRef}>*/}
                    {/*        {promptHistory.map((ph, i) => (*/}
                    {/*            <PriorPrompt key={i} onClick={() => selectPriorPrompt(i)}>*/}
                    {/*                {ph.value}*/}
                    {/*            </PriorPrompt>*/}
                    {/*        ))}*/}
                    {/*    </PromptHistoryPopup>*/}
                    {/*)}*/}
                </PromptBox>
            )}
        </UI.FieldBox>
    );
};

export const PromptEditor = styled(TextareaAutosize)`
    border: none;
    //margin-left: 25px;
    font-family:
        DM Sans,
        serif;
    font-size: 15px;
    letter-spacing: 0.4px;
    padding: 5px;
    resize: none;
    width: 100%;
    ${({ hasPromptButtons }) =>
        hasPromptButtons &&
        `
        border: thin solid #ececec;
        border-radius: 8px;
        margin-bottom: 6px;
        padding: 6px;
    `}

    :focus {
        outline: none;
    }
`;

const PromptBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
`;

export const PromptWrapper = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    //border: thin solid #b9bebc; // #abcece;
    background-color: white;
    padding-left: 26px;

    ::before {
        display: inline-block;
        position: absolute;
        left: 9px;
        top: 6px;
        content: url("/assets/images/bubble-icon-green.svg");
        transform: scale(1.65);
    }
`;

export const PromptButtonBlock = styled.div`
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    align-items: center;
    width: 100%;
    gap: 8px;
`;

const PromptButtons = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    min-width: 200px;
    padding: 12px 8px;

    .ant-btn {
        width: 100%;
    }
`;

export const PromptButton = styled.div`
    padding: 5px 14px;
    border: thin solid #a0ce9d;
    border-radius: 10px;
    font-weight: 500;
    text-align: center;
    min-width: fit-content;
    max-width: 225px;
    box-shadow:
        rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    cursor: pointer;
    flex: 0 0 auto;

    :hover {
        box-shadow:
            0 3px 1px -2px rgb(0 0 0 / 20%),
            0 2px 2px 0 rgb(0 0 0 / 14%),
            0 1px 5px 0 rgb(0 0 0 / 12%);
    }
`;

export const SendPrompt = styled.div`
    display: inline-block;
    position: absolute;
    cursor: pointer;
    right: 6px;
    bottom: 0px;
    width: 34px;
    height: 34px;
    // transform: scale(0.55);
    opacity: 0.6;
    z-index: 10;
`;

export const PromptHistoryBtn = styled.img`
    position: absolute;
    left: 2px;
    top: -21px;
    background-color: rgb(247, 246, 245);
    fill: #628072;
    cursor: pointer;
    transform: scale(0.64);
`;

const PromptHistoryPopup = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: -1px;
    left: 2px;
    border: thin solid lightgray;
    z-index: 2000;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;

const PriorPrompt = styled.div`
    cursor: pointer;
    padding: 3px 6px;

    :hover {
        background-color: #e3e3e3;
    }
`;

export default Prompt;
