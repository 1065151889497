import React, { useMemo, useState } from "react";
import styled from "styled-components/macro";
import TextareaAutosize from "react-textarea-autosize";

import { useFlowGraphStoreMethods, useParameter, useParameterDisabledCheck } from "@mirinae/hyperflow/modules/stores/flowgraph";
import UI from "@mirinae/hyperflow/components/ui/widgets";
import { NumberValue } from "@mirinae/classes/DataValue";
import { ClosedNumberEditor } from "@hyperflow/components/hyperflow/parameters/NumberEntry";

const SliderNumberEntry = ({ step, paramUI, setLocked }) => {
    const { setParameter, closeParamUI } = useFlowGraphStoreMethods();
    const parameter = useParameter(step, paramUI.pathName);
    const disabled = useParameterDisabledCheck(paramUI);
    const range = useMemo(() => ({ from: paramUI.from, scale: (paramUI.to - paramUI.from) / 100 }), [paramUI]);
    const [value, setValue] = useState(parameter?.value || paramUI.defaultValue?.value);
    const sliderValue = ((value - range.from) / (paramUI.to - paramUI.from)) * 100;

    const setNumberParam = e => {
        setParameter(paramUI.pathName, new NumberValue(value, paramUI.locked, paramUI.label));
        if (paramUI.locked) {
            closeParamUI(paramUI, true);
        }
    };

    const rangleSliderChange = e => {
        const floatValue = range.from + Number(e.target.value) * range.scale;
        setValue(parseFloat(floatValue.toFixed(2))); // two places for now ??
    };

    return (
        <UI.FieldBox title={paramUI.label} setLocked={setLocked} locked={paramUI.locked}>
            {paramUI.closed ? (
                <ClosedNumberEditor>{parameter.displayValue()}</ClosedNumberEditor>
            ) : (
                <SliderBox>
                    <SliderPlusValue>
                        <Slider
                            type="range"
                            disabled={disabled}
                            defaultValue={sliderValue}
                            onChange={rangleSliderChange}
                            onBlur={setNumberParam}
                        />
                        <SliderValue>{value}</SliderValue>
                    </SliderPlusValue>
                </SliderBox>
            )}
        </UI.FieldBox>
    );
};

const SliderBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    //padding: 10px 4px 4px 4px;
    gap: 4px;
    width: fit-content;
`;

const Slider = styled.input`
    width: 170px;

    &[type="range"] {
        height: 6px;
        accent-color: #4e80ff;
    }

    &[type="range"]::-webkit-slider-thumb {
        height: 8px !important;
    }
`;

const SliderPlusValue = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
`;

const SliderValue = styled.div`
    text-align: right;
    font-family: monospace;
    font-size: 12px;
    width: 30px;
`;

export default SliderNumberEntry;
