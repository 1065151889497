import React, { useMemo, useRef, useState, useEffect } from "react";
import classNames from "classnames";
import styled from "styled-components/macro";
import { Dropdown } from "antd";

import {
    useFlowGraphStore,
    useFlowGraphStoreMethods,
    useParameter,
    useParameterDisabledCheck,
} from "@mirinae/hyperflow/modules/stores/flowgraph";
import UI from "@mirinae/hyperflow/components/ui/widgets";
import { getByPath } from "@mirinae/shared/modules/utils/pathUtils";
import { ChoiceValue } from "@mirinae/classes/DataValue";

const selectIntro = "Select option...";
const clearSelection = ["-", { label: <span style={{ color: "#7d7d7d" }}>Clear selection</span>, value: selectIntro }];

const Select = ({ step, paramUI, setLocked }) => {
    const { setParameter, setInputs, setOutputs, closeParamUI } = useFlowGraphStoreMethods();
    const parameter = useParameter(step, paramUI.pathName);
    const disabled = useParameterDisabledCheck(paramUI);
    const parameters = useFlowGraphStore(state => state.flow.steps[step.index]?.parameters);

    const [selected, setSelected] = useState(parameter?.value || selectIntro);

    const value = useMemo(() => parameter?.value || paramUI.defaultValue?.value, [parameter]);
    const required = useMemo(() => paramUI.awaiting && !parameter?.value, [paramUI, parameter]);

    const items = useMemo(() => {
        // allow for indirect choices list derivation from other parameter values
        let choices = Array.isArray(paramUI.choices)
            ? paramUI.choices
            : "parameter" in paramUI.choices
              ? getByPath(parameters, paramUI.choices.parameter)?.value || []
              : [];
        if (selected !== selectIntro) choices = choices.concat(clearSelection);
        // map to ant.d menu items
        return choices.map(c =>
            c === "-"
                ? { type: "divider" }
                : {
                      label: c.label || c,
                      key: c.value || c,
                      disabled: c.status === "disabled",
                  }
        );
    }, [paramUI, parameters, selected]);

    const onSelect = ({ item, key, domEvent: e }) => {
        // e.stopPropagation();
        // e.preventDefault();
        if (key === selectIntro) {
            setSelected(key);
            paramUI.awaiting = true;
            setParameter(paramUI.pathName, new ChoiceValue(undefined, false, paramUI.label));
            return;
        }
        //
        paramUI.awaiting = false;
        const value = key;
        setSelected(items.find(i => i.key === key).label);
        setParameter(paramUI.pathName, new ChoiceValue(value, paramUI.locked, paramUI.label));
        if (paramUI.locked) {
            closeParamUI(paramUI, true);
        }
        if (paramUI.type === "dataTypeChoice") {
            // update port types of designated ports if a dataTypeChoice parameter
            const updatedInputs = step.node.inputs.map(ip => {
                if (paramUI.inputPortsToUpdate?.includes(ip.pathName) || paramUI.inputPortsToUpdate?.includes("*")) {
                    ip.type = value;
                }
                return ip;
            });
            setInputs(step.node.nodeID, updatedInputs);
            //
            const updatedOutputs = step.node.outputs.map(op => {
                if (paramUI.outputPortsToUpdate?.includes(op.pathName)) {
                    op.type = value;
                }
                return op;
            });
            setOutputs(step.node.nodeID, updatedOutputs);
        }
    };

    return (
        <UI.FieldBox
            title={paramUI.label}
            setLocked={setLocked}
            locked={paramUI.locked}
            accept={!paramUI.closed && value && onSelect}
            required={required}
            style={paramUI.style}
        >
            {paramUI.closed ? (
                parameter.displayValue()
            ) : (
                <SelectorDropdown
                    menu={{ items, onClick: onSelect }}
                    disabled={disabled}
                    trigger={["click"]}
                    autoAdjustOverflow={false}
                    getPopupContainer={te => te.parentNode}
                >
                    <Selector
                        onScroll={e => e.stopPropagation()} // Prevents the event from bubbling
                    >
                        <Value>{selected}</Value> <img src="/assets/images/dropdown-chevron.svg" alt="menu" />
                    </Selector>
                </SelectorDropdown>
            )}
        </UI.FieldBox>
    );
};

const Selector = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 100%;
`;

const SelectorDropdown = styled(Dropdown)``;

const Value = styled.div`
    font-size: 13px;
`;

export default Select;
