import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { Button } from "antd";

import { useFlowGraphStoreMethods, useParameterDisabledCheck } from "@mirinae/hyperflow/modules/stores/flowgraph";
import flowgraphEngine from "@hyperflow/modules/engines/flowgraph";

const PreviewButton = ({ mode, paramUI, step: { node } }) => {
    const disabled = useParameterDisabledCheck(paramUI) || mode === "historical";
    const { noteHadParameterInput } = useFlowGraphStoreMethods();

    if ((mode === "configuring" && node?.name !== "visualizeVectorDB") || mode === "historical") return;

    noteHadParameterInput(true);

    const previewClicked = e => {
        flowgraphEngine.runPreview();
    };

    // no, if this node is in the flow, the dev always wants us to stop to allow a visualization choice
    // useEffect(() => {
    //     // trying this: if all params locked, don't wait for Preview, is that right?   Otherwise we hold up free-running flows at these button breaks
    //     if (mode === 'current' && allParametersLocked(paramUI)) {
    //         setParameter(paramUI.pathName, new BooleanValue(true));
    //     }
    // }, []);

    return (
        <PreviewBox>
            <Button disabled={disabled} onClick={previewClicked} style={{ minWidth: 106, width: "fit-content" }}>
                {paramUI.label || "Preview"}
            </Button>
        </PreviewBox>
    );
};

const PreviewBox = styled.div`
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin: 16px 0 2px 0;
`;

export default PreviewButton;
