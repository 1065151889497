import React, { useState, useMemo } from "react";
import styled from "styled-components/macro";

import { apiPaths } from "@mirinae/defines/paths";
import { httpAPI } from "@mirinae/apis/http";
import {
    useFlowGraphStore,
    useFlowGraphStoreMethods,
    useParameter,
    useParameterDisabledCheck,
} from "@mirinae/hyperflow/modules/stores/flowgraph";
import { getByPath } from "@mirinae/shared/modules/utils/pathUtils";
import { PromptTemplateValue, UploadsValue } from "@mirinae/classes/DataValue";
import UI from "@hyperflow/components/ui/widgets";

const ContentUpload = ({ step, paramUI, mode, setLocked }) => {
    const { setParameter, setBusy, closeParamUI } = useFlowGraphStoreMethods();
    const [uploadCount, setUploadCount] = useState(0);
    const parameter = useParameter(step, paramUI.pathName);
    const parameters = useFlowGraphStore(state => state.flow.steps[step.index]?.parameters);
    const disabledByDependency = useParameterDisabledCheck(paramUI);
    let uploadedLabel = "";
    const files = [];

    const disabled = useMemo(() => uploadCount > 0 || disabledByDependency, [disabledByDependency, uploadCount]);
    const required = useMemo(() => (paramUI.awaiting && !parameter?.value) || parameter?.value === undefined, [paramUI, parameter]);

    // handle file or directory drops
    const processFileEntry = async (fileEntry, formData, mimetypePat) => {
        const file = await new Promise((resolve, reject) => fileEntry.file(file => resolve(file), reject));
        if (file.type.match(mimetypePat)) {
            files.push([file, fileEntry.fullPath]);
        }
    };

    const processDirectoryEntry = async (directoryEntry, formData, mimetypePat) => {
        const directoryReader = directoryEntry.createReader();
        const entries = await new Promise((resolve, reject) => {
            directoryReader.readEntries(entries => resolve(entries), reject);
        });
        for await (const entry of entries) {
            await processEntry(entry, formData, mimetypePat);
        }
    };

    const processEntry = async (entry, formData, mimetypePat) => {
        if (!uploadedLabel) uploadedLabel = entry.name;
        if (entry.isFile) {
            await processFileEntry(entry, formData, mimetypePat);
        } else if (entry.isDirectory) {
            await processDirectoryEntry(entry, formData, mimetypePat);
        }
    };

    const uploadContent = async e => {
        const formData = new FormData();

        const importFormat = getByPath(parameters, "importFormat")?.value;
        const mimetypePat = new RegExp(getByPath(parameters, "importTypes")?.value[importFormat]) || "text/plain";

        if (e.target.webkitEntries?.length > 0) {
            for await (const entry of e.target.webkitEntries) {
                await processEntry(entry, formData, mimetypePat);
            }
        } else {
            const file = e.target.files[0];
            if (file?.type.match(mimetypePat)) {
                files.push([file, undefined]);
            }
        }

        if (files.length > 1) {
            files.forEach(([file, path]) => formData.append("files[]", file, path)); // Use 'files[]' for multiple files
        } else if (files.length > 0) {
            formData.append("file", files[0][0]);
        } else {
            return; // hey - report error somehow
        }
        setBusy(true, "Uploading files...");

        httpAPI("", apiPaths.uploadContent, {
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
            .then(response => {
                console.log(response);
                setBusy(false, "");
                const {
                    data: { uploads, filename },
                } = response;
                const displayValue = files.length > 1 ? `${files.length} files` : filename;
                setParameter(paramUI.pathName, new UploadsValue(uploads, false, displayValue));
                setUploadCount(files.length);
            })
            .catch(error => {
                console.log(error);
            });
    };

    const acceptFileUpload = () => {
        setParameter(paramUI.pathName, new UploadsValue(parameter.value, paramUI.locked, parameter.displayValue()));
        paramUI.awaiting = false;
        if (paramUI.locked) {
            closeParamUI(paramUI, true);
        }
    };

    const resetUpload = () => {
        if (!paramUI.locked) {
            setParameter(paramUI.pathName, new UploadsValue(undefined, false, ""));
            paramUI.awaiting = true;
            setUploadCount(0);
        }
    };

    return paramUI.closed || parameter?.value ? (
        <UI.FieldBox
            title={paramUI.label || "File(s) upload"}
            setLocked={setLocked}
            locked={paramUI.locked}
            required={required}
            accept={!paramUI.closed && acceptFileUpload}
            style={{ ...(paramUI.style || {}) }}
        >
            <DisplayUpload
                disabled={paramUI.locked || mode === "historical"}
                locked={paramUI.locked}
                onClick={() => mode !== "historical" && resetUpload()}
            >
                {parameter.displayValue()}
            </DisplayUpload>
        </UI.FieldBox>
    ) : (
        <UploadPad htmlFor="content-input-id" disabled={disabled} required={required}>
            <input
                type="file"
                id="content-input-id"
                multiple
                onChange={uploadContent}
                disabled={disabled}
                style={{ opacity: 0, height: 45, width: "100%", cursor: "pointer" }}
            />
            <span>{uploadCount > 0 ? `Uploaded\n${uploadCount} file(s)` : "Select file(s) or drop files or folders"}</span>
        </UploadPad>
    );
};

const UploadPad = styled.label`
    position: relative;
    padding: 10px 0px;
    border: thin solid ${({ required }) => (required ? "#cf4b4b" : "lightgray")};
    border-radius: 15px;
    cursor: ${props => (props.disabled ? "default" : "pointer")};
    margin: 7px 19px 0px 19px;

    :hover {
        ${({ disabled }) => !disabled && "border-color: rgb(42, 150, 255);"}
    }
    span {
        text-align: center;
        width: 120px;
        position: absolute;
        top: calc(50% - 20px);
        left: calc(50% - 59px);
        pointer-events: none;
        white-space: pre-wrap;
        color: ${props => (props.disabled ? "#aeaeae" : "black")};
    }
`;

const DisplayUpload = styled.div`
    padding: 4px;
    cursor: ${props => (props.disabled ? "inherit" : "pointer")};
    user-select: none;
    ${props => !props.locked && !props.disabled && "background: white;"}
`;

export default ContentUpload;
