import React, { useRef, useState } from "react";
import styled from "styled-components/macro";
import TextareaAutosize from "react-textarea-autosize";

import {
    useFlowGraphStore,
    useFlowGraphStoreMethods,
    useParameter,
    useParameterDisabledCheck,
} from "@mirinae/hyperflow/modules/stores/flowgraph";
import UI from "@mirinae/hyperflow/components/ui/widgets";
import { getByPath, setByPath } from "@mirinae/shared/modules/utils/pathUtils";
import { URLValue } from "@mirinae/classes/DataValue";

const URL = ({ step, paramUI, setLocked }) => {
    const { setParameter, closeParamUI } = useFlowGraphStoreMethods();
    const parameter = useParameter(step, paramUI.pathName);
    const disabled = useParameterDisabledCheck(paramUI);
    const editorRef = useRef();

    const text = parameter?.value || paramUI.defaultValue?.value;

    // hey!! do some URL validation!!!

    const setURLParam = e => {
        setParameter(paramUI.pathName, new URLValue(e.target.value, paramUI.locked, paramUI.label));
    };

    return (
        <UI.FieldBox
            title={paramUI.label}
            setLocked={setLocked}
            locked={paramUI.locked}
            popupEditor
            fieldStyle={{ textAlign: "left", overflowWrap: "anywhere" }}
        >
            {paramUI.closed ? (
                parameter.displayValue()
            ) : (
                <URLEditor ref={editorRef} disabled={disabled} defaultValue={text} maxRows={8} minRows={3} onBlur={setURLParam} />
            )}
        </UI.FieldBox>
    );
};

const URLEditor = styled(TextareaAutosize)`
    border: none;
    // margin-left: 25px;
    font-family:
        DM Sans,
        serif;
    font-size: 12px;
    letter-spacing: 0.4px;
    padding: 5px 7px;
    resize: none;
    margin-bottom: -3px;
    width: 100%;
    box-sizing: border-box;

    :focus {
        outline: none;
    }
`;

export default URL;
