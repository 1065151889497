import React, { useMemo, useRef, useState } from "react";
import styled from "styled-components/macro";
import TextareaAutosize from "react-textarea-autosize";

import {
    useFlowGraphStore,
    useFlowGraphStoreMethods,
    useParameter,
    useParameterDisabledCheck,
} from "@mirinae/hyperflow/modules/stores/flowgraph";
import UI from "@mirinae/hyperflow/components/ui/widgets";
import { getByPath, setByPath } from "@mirinae/shared/modules/utils/pathUtils";
import { NumberValue } from "@mirinae/classes/DataValue";

const NumberEntry = ({ step, paramUI, setLocked }) => {
    const { setParameter, closeParamUI } = useFlowGraphStoreMethods();
    const parameter = useParameter(step, paramUI.pathName);
    const disabled = useParameterDisabledCheck(paramUI);
    const [invalid, setInvalid] = useState(false);
    const editorRef = useRef();

    const required = useMemo(() => paramUI.awaiting && !parameter?.value, [paramUI, parameter]);
    const number = parameter?.value || paramUI.defaultValue?.value;

    const setNumberParam = e => {
        if (!invalid) {
            const value =
                !e.target.value || e.target.value === paramUI.defaultValue?.value ? paramUI.defaultValue?.value : Number(e.target.value);
            setParameter(paramUI.pathName, new NumberValue(value, paramUI.locked, paramUI.label));
            if (paramUI.locked) {
                closeParamUI(paramUI, true);
            }
        }
    };

    const checkForNumber = e => {
        setInvalid(Number.isNaN(Number(e.target.value)) && e.target.value !== paramUI.defaultValue?.value);
    };

    return (
        <UI.FieldBox
            title={paramUI.label}
            setLocked={setLocked}
            locked={paramUI.locked}
            required={required}
            accept={!paramUI.closed && typeof number === "number" && !isNaN(number) && setNumberParam}
        >
            {paramUI.closed ? (
                <ClosedNumberEditor>{parameter.displayValue()}</ClosedNumberEditor>
            ) : (
                <NumberEditor
                    className={`numberEditor ${paramUI.pathName.replace(".", "_")}`}
                    ref={editorRef}
                    disabled={disabled}
                    $invalid={invalid}
                    defaultValue={number}
                    minRows={1}
                    onChange={checkForNumber}
                    onBlur={setNumberParam}
                />
            )}
        </UI.FieldBox>
    );
};

export const NumberEditor = styled(TextareaAutosize)`
    border: none;
    // margin-left: 25px;
    font-family:
        DM Sans,
        serif;
    font-size: 12px;
    // letter-spacing: 0.4px;
    text-align: right;
    padding: 2px 4px;
    margin-bottom: -3px;
    resize: none;
    min-width: 120px;
    width: 100%;
    box-sizing: border-box;
    ${props => props.$invalid && "color: red;"};

    :focus {
        outline: none;
    }
`;

export const ClosedNumberEditor = styled.div`
    text-align: right;
    width: 100%;
`;

export default NumberEntry;
