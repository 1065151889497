import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import TextareaAutosize from "react-textarea-autosize";

import { useFlowGraphStoreMethods, useParameter, useParameterDisabledCheck } from "@mirinae/hyperflow/modules/stores/flowgraph";
import UI from "@mirinae/hyperflow/components/ui/widgets";
import { CodeValue } from "@mirinae/classes/DataValue";

const Code = ({ flow, step, paramUI, setLocked }) => {
    const { setParameter, closeParamUI } = useFlowGraphStoreMethods();
    const parameter = useParameter(step, paramUI.pathName);
    const disabled = useParameterDisabledCheck(paramUI);
    const [editorRef, setEditorRef] = useState(null);

    const code = parameter?.value || paramUI.defaultValue?.value;

    // implement 4-space tabbing
    useEffect(() => {
        if (editorRef) {
            const handleTab = event => {
                if (event.key === "Tab") {
                    event.preventDefault();
                    const tabSpaces = "  "; // tab = 4 spaces
                    const start = editorRef.selectionStart;
                    const end = editorRef.selectionEnd;
                    const text = editorRef.value;
                    editorRef.value = text.substring(0, start) + tabSpaces + text.substring(end);
                    editorRef.selectionStart = editorRef.selectionEnd = start + tabSpaces.length;
                }
            };

            editorRef.addEventListener("keydown", handleTab);
            return () => {
                if (editorRef) editorRef.removeEventListener("keydown", handleTab);
            };
        }
    }, [editorRef, step, paramUI]);

    const setCodeParam = e => {
        setParameter(paramUI.pathName, new CodeValue(e.target.value, paramUI.locked, paramUI.label));
        if (paramUI.locked) {
            closeParamUI(paramUI, true);
        }
    };

    return !paramUI.closed || parameter.value ? (
        <UI.FieldBox title={paramUI.label} setLocked={setLocked} locked={paramUI.locked} popupEditor>
            {paramUI.closed ? (
                <ClosedForm>{parameter.displayValue()}</ClosedForm>
            ) : (
                <CodeEditor
                    ref={ref => setEditorRef(ref)}
                    disabled={disabled}
                    defaultValue={code}
                    rows={paramUI.rows || 10}
                    onBlur={setCodeParam}
                />
            )}
        </UI.FieldBox>
    ) : null;
};

// export const CodeEditor = styled(TextareaAutosize)`
export const CodeEditor = styled.textarea`
    border: none;
    font-family:
        DM Mono,
        monospace,
        sans-serif;
    font-size: 11px;
    white-space: nowrap;
    padding: 5px 7px;
    resize: none;
    margin-bottom: -3px;
    width: 100%;
    box-sizing: border-box;

    :focus {
        outline: none;
    }
`;

const ClosedForm = styled.div`
    max-width: 700px;
    white-space: pre-wrap;
    padding: 8px;
    text-align: left;
    max-height: 10em;
    overflow: auto;
`;

export default Code;
