import React, { useRef, useState, useEffect, useMemo } from "react";
import styled from "styled-components/macro";
import { useImmer } from "use-immer";
import { Button } from "antd";

import { useFlowGraphStore, useFlowGraphStoreMethods, useParameter } from "@mirinae/hyperflow/modules/stores/flowgraph";
import { SimpleButton } from "@mirinae/hyperflow/components/ui/widgets";
import { getByPath } from "@mirinae/shared/modules/utils/pathUtils";
import { BranchValue } from "@mirinae/classes/DataValue";
import flowgraphEngine from "@mirinae/hyperflow/modules/engines/flowgraph";
import { TextEditor } from "@hyperflow/components/hyperflow/parameters/TextEntry";
import UI from "@mirinae/hyperflow/components/ui/widgets";

const BranchChoice = ({ flow, mode, step, paramUI, setLocked }) => {
    const [disabled, setDisabled] = useImmer([]);
    const parameters = useFlowGraphStore(state => state.flow.steps[step.index]?.parameters);
    const parameter = useParameter(step, paramUI.pathName);
    const node = useFlowGraphStore(state => state.flow.flowGraph.nodes[step.nodeID]);
    const { setParameter, setEdgeHighlight, updateParameterSpec, updateExits, allParametersLocked, resetHadParameterInput } =
        useFlowGraphStoreMethods();

    // the branch buttons replace the main OK button
    if (mode !== "historical") resetHadParameterInput();

    const branches = useMemo(() => {
        // allow for indirect branches list derivation from other parameter values
        //                 branches: parameters.branches.value.map((branch, i) => ({ label: branch, edgeIndex: i })),  // this seems a little hard-coded, should be following node's specs
        if (Array.isArray(paramUI.branches)) return paramUI.branches;
        else if ("parameter" in paramUI.branches) {
            return getByPath(parameters, paramUI.branches.parameter)?.value;
        }
    }, [paramUI, parameters]);

    const builtInFlow = useMemo(() => flow.flowGraph.type === "built-in-flow-graph", [flow.flowGraph]);

    useEffect(() => {
        branches.forEach((branch, i) => {
            const disabled = branch.dependsOn?.some(param => getByPath(parameters, param) === undefined);
            setDisabled(state => {
                state[i] = disabled;
            });
        });
    }, [parameters]);

    const onSelectBranch = (branch, e) => {
        if (branch.runPreview) {
            flowgraphEngine.runPreview();
        } else {
            setParameter(paramUI.pathName, new BranchValue(branch.edgeIndex, false, branch.label));
            const nodeID = flow.currentNodeID;
            const edgeID = flow.flowGraph.nodes[nodeID]?.exits[branch.edgeIndex].edgeID;
            if (edgeID !== undefined) setEdgeHighlight(nodeID, edgeID, false);
        }
    };

    const mouseAction = (action, i) => {
        if (!branches[i].runPreview) {
            const nodeID = flow.currentNodeID;
            const edgeID = flow.flowGraph.nodes[nodeID]?.exits[branches[i].edgeID];
            if (edgeID !== undefined) setEdgeHighlight(nodeID, edgeID, action === "enter");
        }
    };

    const setLabelText = (e, i) => {
        const branchesUpdated = [...branches];
        branchesUpdated[i].label = e.target.value;
        updateParameterSpec(flow.currentNodeID, paramUI, { branches: branchesUpdated });
    };

    const duplicateBranch = i => {
        // update branches paramSpec
        let branchesUpdated = [...branches];
        branchesUpdated.splice(i + 1, 0, { label: `${branches[i].label} copy` });
        branchesUpdated = branchesUpdated.map((b, i) => ({ ...b, edgeIndex: i }));
        updateParameterSpec(flow.currentNodeID, paramUI, { branches: branchesUpdated });

        // update node exit details
        const updatedExits = [...node.exits];
        updatedExits.splice(i + 1, 0, { ...node.exits[i], edgeID: null, label: `${node.exits[i]} copy` });
        updateExits(flow.currentNodeID, updatedExits);
    };

    const deleteBranch = i => {
        if (branches.length > 1) {
            // update branches paramSpec
            let branchesUpdated = [...branches];
            branchesUpdated.splice(i, 1);
            branchesUpdated = branchesUpdated.map((b, i) => ({ ...b, edgeIndex: i }));
            updateParameterSpec(flow.currentNodeID, paramUI, { branches: branchesUpdated });

            // update node exit details
            const updatedExits = [...node.exits];
            updatedExits.splice(i, 1);
            updateExits(flow.currentNodeID, updatedExits);
        }
    };

    return mode === "configuring" ? (
        <UI.FieldBox title="Branches" setLocked={!builtInFlow && setLocked} locked={paramUI.locked}>
            <ConfigureBox>
                {branches.map((branch, i) => (
                    <Branch key={branch.label}>
                        <BranchButtonLabel
                            defaultValue={branch.label}
                            disabled={builtInFlow || paramUI.locked}
                            onBlur={e => setLabelText(e, i)}
                        />
                        {!builtInFlow && (
                            <>
                                <Tool src="/assets/images/duplicate.svg" onClick={() => duplicateBranch(i)} />
                                <Tool src="/assets/images/delete.svg" onClick={() => deleteBranch(i)} />
                            </>
                        )}
                    </Branch>
                ))}
            </ConfigureBox>
        </UI.FieldBox>
    ) : mode === "current" ? (
        <ChoiceBox>
            <ChoiceButtons>
                {branches.map((branch, i) => (
                    <Button
                        key={i}
                        disabled={disabled[i]}
                        onClick={e => onSelectBranch(branch, e)}
                        onMouseEnter={() => mouseAction("enter", i)}
                        onMouseLeave={() => mouseAction("leave", i)}
                    >
                        {branch.label}
                    </Button>
                ))}
            </ChoiceButtons>
        </ChoiceBox>
    ) : (
        <UI.FieldBox title="Branch choice">{parameter.displayValue()}</UI.FieldBox>
    );
};

const ChoiceBox = styled.div`
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    //justify-content: center;
    align-items: center;
    width: 100%;
    gap: 8px;
    margin: 16px 0;
`;

const ChoiceButtons = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    min-width: 200px;

    .ant-btn {
        width: 100%;
    }
`;

const ConfigureBox = styled(ChoiceBox)`
    gap: 8px;
    margin: 4px 0;
`;

const BranchButton = styled(Button)`
    min-width: 106px;
    font-weight: 400;
`;

const Branch = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
`;

const Tool = styled.img`
    cursor: pointer;
`;

const BranchButtonLabel = styled(TextEditor)`
    padding: 4px 12px;
    margin-right: 7px;
    border: thin solid #cbcbcb;
    border-radius: 5px;
    font-weight: 600;
    box-shadow:
        rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
`;

export default BranchChoice;
