import React, { useEffect, useMemo, useRef } from "react";
import styled from "styled-components/macro";

import {
    useFlowGraphStore,
    useFlowGraphStoreMethods,
    useParameter,
    useParameterDisabledCheck,
} from "@mirinae/hyperflow/modules/stores/flowgraph";
import { BooleanValue } from "@mirinae/classes/DataValue";
import { Button } from "antd";

const RunButton = ({ mode, paramUI }) => {
    const { setParameter, allParametersLocked } = useFlowGraphStoreMethods();
    const busy = useFlowGraphStore(state => state.flow.busy);
    const paramDIsabledCheck = useParameterDisabledCheck(paramUI);
    const disabled = paramUI.disabled || paramDIsabledCheck;

    const onRun = e => {
        setParameter(paramUI.pathName, new BooleanValue(true, paramUI.locked, paramUI.label));
    };

    // NOTE that we have now disabled all ParameterRequest instances of "runButton" as the step from node parameter input to input is handled
    // by an automatically-generated "runButton".   This may be reversed, but for now, this component only displays runButtons

    useEffect(() => {
        // allow Run's with cmd-enter
        const keyDown = e => {
            if (mode !== "historical" && !paramUI.closed && e.metaKey && e.key === "Enter") {
                if (document.activeElement) document.activeElement.blur();
                // let the blur() above complete in case it causes a editing parameter to be accepted and set
                setTimeout(() => setParameter(paramUI.pathName, new BooleanValue(true, paramUI.locked, paramUI.label)), 0);
                e.preventDefault();
                e.stopPropagation();
            }
        };
        document.addEventListener("keydown", keyDown);
        return () => document.removeEventListener("keydown", keyDown);
    }, [paramUI]);

    return paramUI.type === "runButton" && mode !== "historical" ? (
        <RunBox>
            <Button
                disabled={disabled}
                onClick={e => onRun(e)}
                loading={busy}
                iconPosition="end"
                type="primary"
                style={{ minWidth: 106, width: "fit-content" }}
            >
                {paramUI.label}
            </Button>
            <BusyStatus />
        </RunBox>
    ) : null;
};

const RunBox = styled.div`
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin: 16px 0 25px 0;
`;

export const BusyStatus = ({}) => {
    const flow = useFlowGraphStore(state => state.flow);
    const currentStepIndex = useFlowGraphStore(state => state.flow.currentStepIndex);
    const steps = useFlowGraphStore(state => state.flow.steps);
    const busy = useFlowGraphStore(state => state.flow.busy);
    const busyMessage = useFlowGraphStore(state => state.flow.busyMessage);
    const busyStatus = useFlowGraphStore(state => state.flow.busyStatus);
    const currentStep = useMemo(() => steps[currentStepIndex], [flow, steps]);

    return (
        <Busy>
            {busyMessage}
            {busyStatus && <span>{busyStatus}</span>}
        </Busy>
    );
};

const Busy = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;
    span {
        font-size: 14px;
        color: #1890ff;
        margin-left: 8px;
    }
`;

export default RunButton;
