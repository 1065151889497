import React, { useMemo, useRef } from "react";
import styled from "styled-components/macro";
import TextareaAutosize from "react-textarea-autosize";

import { useFlowGraphStoreMethods, useParameter, useParameterDisabledCheck } from "@mirinae/hyperflow/modules/stores/flowgraph";
import UI from "@mirinae/hyperflow/components/ui/widgets";
import { TextValue } from "@mirinae/classes/DataValue";

const TextEntry = ({ flow, step, paramUI, setLocked }) => {
    const { setParameter, closeParamUI } = useFlowGraphStoreMethods();
    const parameter = useParameter(step, paramUI.pathName);
    const disabled = useParameterDisabledCheck(paramUI);
    const editorRef = useRef();

    const required = useMemo(() => paramUI.awaiting && !parameter?.value, [paramUI, parameter]);
    const text = parameter?.value || paramUI.defaultValue?.value;

    const setTextParam = e => {
        setParameter(paramUI.pathName, new TextValue(e.target.value, paramUI.locked, paramUI.label));
        if (paramUI.locked) {
            closeParamUI(paramUI, true);
        }
    };

    return (
        <UI.FieldBox
            title={paramUI.label}
            setLocked={setLocked}
            locked={paramUI.locked}
            required={required}
            popupEditor
            style={{ whiteSpace: "preWrap", minWidth: 170, ...(paramUI.style || {}) }}
        >
            {paramUI.closed ? (
                <ClosedForm>{parameter.displayValue() || ""}</ClosedForm>
            ) : (
                <TextEditor
                    className={`textEntry ${paramUI.pathName.replace(".", "_")}`}
                    ref={editorRef}
                    disabled={disabled}
                    defaultValue={text}
                    minRows={paramUI.minRows || 2}
                    onBlur={setTextParam}
                    style={paramUI.textStyle}
                />
            )}
        </UI.FieldBox>
    );
};

export const TextEditor = styled(TextareaAutosize)`
    border: none;
    // margin-left: 25px;
    font-family:
        DM Sans,
        serif;
    font-size: 12px;
    // letter-spacing: 0.4px;
    padding: 2px 4px;
    resize: none;
    margin-bottom: -3px;
    width: 100%;
    box-sizing: border-box;

    :focus {
        outline: none;
    }
`;

const ClosedForm = styled.div`
    white-space: pre-wrap;
    min-height: 1.2em;
    text-align: left;
`;

export default TextEntry;
