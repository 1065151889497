import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components/macro";

import { useFlowGraphStore, useFlowGraphStoreMethods, useParameter } from "@hyperflow/modules/stores/flowgraph";
import { getByPath } from "@mirinae/shared/modules/utils/pathUtils";
import { BooleanValue } from "@mirinae/classes/DataValue";
import UI from "@mirinae/hyperflow/components/ui/widgets";

const CheckBox = ({ step, paramUI, setLocked }) => {
    const parameters = useFlowGraphStore(state => state.flow.steps[step.index]?.parameters);
    const parameter = useParameter(step, paramUI.pathName);
    const { setParameter, closeParamUI } = useFlowGraphStoreMethods();

    const disabled = useMemo(() => paramUI.dependsOn?.some(param => getByPath(parameters, param) === undefined), [parameters]);
    const checked = useMemo(() => parameter?.value || (parameter?.value !== false && paramUI.defaultValue?.value), [parameters]);

    const onChange = e => {
        setParameter(paramUI.pathName, new BooleanValue(e.target.checked, paramUI.locked, paramUI.label));
        if (paramUI.locked) {
            closeParamUI(paramUI, true);
        }
    };

    return paramUI.closed ? (
        <UI.FieldBox title={paramUI.label} setLocked={setLocked} locked={paramUI.locked}>
            {parameter.value ? "Enabled" : "Disabled"}
        </UI.FieldBox>
    ) : (
        <UI.FieldBox setLocked={setLocked} locked={paramUI.locked}>
            <CheckboxBox>
                <Checkbox type="checkbox" defaultChecked={checked} disabled={disabled} onChange={e => onChange(e)} />
                <span>{paramUI.label}</span>
            </CheckboxBox>
        </UI.FieldBox>
    );
};

const CheckboxBox = styled.div`
    display: flex;
    flex-direction: row;
    -webkit-box-pack: center;
    justify-content: flex-start;
    align-items: center;

    span {
        width: fit-content;
        min-width: 106px;
        font-weight: 400;
        text-align: left;
        margin-left: 3px;
    }
`;

const Checkbox = styled.input`
    padding-right: 5px;
`;

export default CheckBox;
