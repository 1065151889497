import React, { useMemo, useEffect, useRef } from "react";
import styled from "styled-components/macro";
import { css } from "styled-components";

const DirectoryTree = ({
    filter,
    selectMode,
    searchString,
    selectable,
    onSelect,
    openToLevel: levelToOpenTo,
    levelIndent = 14,
    useDirectoryStore,
    children: nodeRenderer,
}) => {
    const { toggleSubtreeOpen, toggleChecked, oneChecked, clearAllChecks, openToLevel, setSelectedNode, filteredNodeMap } =
        useDirectoryStore(state => state.methods);
    const nodeMap = useDirectoryStore(state => state.nodeMap);
    const selectedNodeID = useDirectoryStore(state => state.selectedNodeID);
    const rootNodeID = useDirectoryStore(state => state.rootNodeID);
    const displayNodeMap = useMemo(() => (filter ? filteredNodeMap(filter, searchString) : nodeMap), [nodeMap, filter, searchString]);
    const interacting = useRef(false);
    const treeBoxRef = useRef(null);

    useEffect(() => {
        if (!interacting.current && Object.keys(nodeMap).length > 0) {
            clearAllChecks();
        }
    }, [selectMode, nodeMap]);

    useEffect(() => {
        if (levelToOpenTo !== undefined && !interacting.current && Object.keys(nodeMap).length > 0) {
            openToLevel(levelToOpenTo);
        }
    }, [levelToOpenTo, nodeMap]);

    const selectNode = (node, e) => {
        e.stopPropagation();
        setSelectedNode(node.id);
        onSelect(node, e);
    };

    const checkOutsideClick = e => {
        if (e.target === treeBoxRef.current || treeBoxRef.current?.contains(e.target)) return;
        setSelectedNode(null);
    };

    useEffect(() => {
        document.body.addEventListener("click", checkOutsideClick, false);
        return () => {
            document.body.removeEventListener("click", checkOutsideClick);
        };
    }, [nodeMap]);

    const LeafNode = ({ node, level }) => {
        if (node.isLeaf && level <= 1) return null; // don't clutter with untagged root nodes

        const clickCheckbox = () => {
            interacting.current = true;
            toggleChecked(node.id);
        };

        const clickRadioBtn = () => {
            if (!node.checked) {
                interacting.current = true;
                oneChecked(node.id);
            }
        };

        const onDragStart = e => {
            e.dataTransfer.setData("directory/node", node.id);
            e.dataTransfer.effectAllowed = "copy";
        };

        return (
            <LeafNodeWrapper
                node={node}
                level={level}
                indent={level * levelIndent + 16}
                selectable={selectable}
                selected={selectable && selectedNodeID === node.id}
                title={node.name}
            >
                {selectMode === "multiple" ? (
                    <Clickable onClick={clickCheckbox}>
                        <SelectorIcon src={`/assets/images/${node.checked ? "check-box-checked" : "check-box-unchecked"}.svg`} />
                        <span>{node.name}</span>
                    </Clickable>
                ) : selectMode === "single" ? (
                    <Clickable onClick={clickRadioBtn}>
                        <SelectorIcon src={`/assets/images/${node.checked ? "radio-button-checked" : "radio-button-unchecked"}.svg`} />
                        <span>{node.name}</span>
                    </Clickable>
                ) : (
                    <LeafNodeEntry
                        contentEditable={false}
                        selectable={selectable}
                        onClick={e => selectNode(node, e)}
                        draggable={node.type === "built-in-node-class"}
                        onDragStart={onDragStart}
                    >
                        {node.name}
                    </LeafNodeEntry>
                )}
            </LeafNodeWrapper>
        );
    };

    const Subtree = ({ subtreeID, level }) => {
        const subtree = useDirectoryStore(state => state.nodeMap[subtreeID]);

        const toggleSubtree = () => {
            interacting.current = true;
            toggleSubtreeOpen(subtreeID);
        };

        const clickCheckbox = () => {
            interacting.current = true;
            toggleChecked(subtreeID);
        };

        return (
            subtree.id in displayNodeMap && (
                <>
                    <SubtreeWrapper
                        subtree={subtree}
                        level={level}
                        indent={level * levelIndent}
                        title={subtree.name}
                        onClick={toggleSubtree}
                    >
                        <Expander>{subtree.open ? "-" : "+"}</Expander>
                        {selectMode === "multiple" ? (
                            <Clickable onClick={clickCheckbox}>
                                <SelectorIcon src={`/assets/images/${subtree.checked ? "check-box-checked" : "check-box-unchecked"}.svg`} />
                                <span>{subtree.name}</span>
                            </Clickable>
                        ) : (
                            <span>{subtree.name}</span>
                        )}
                    </SubtreeWrapper>
                    {subtree.open &&
                        subtree.children
                            .filter(id => id in displayNodeMap)
                            .sort(sorter)
                            .map((id, i) => <Node key={i} nodeID={id} level={level + 1} />)}
                </>
            )
        );
    };

    const Node = ({ nodeID, level }) => {
        const LeafNodeRenderer = nodeRenderer || LeafNode;
        const node = useDirectoryStore(state => state.nodeMap[nodeID]); // this limits re-rendering to changes only in the IDed node!

        return (
            node.id in displayNodeMap && (
                <NodeWrapper className="directory-tree-node" node={node} level={level}>
                    {node.isLeaf ? <LeafNodeRenderer node={node} level={level} /> : <Subtree subtreeID={nodeID} level={level} />}
                </NodeWrapper>
            )
        );
    };

    const sorter = (id1, id2) => (displayNodeMap[id1].name < displayNodeMap[id2].name ? -1 : 1);

    return displayNodeMap[rootNodeID]?.children.length > 0 ? (
        <TreeWrapper ref={treeBoxRef}>
            {displayNodeMap[rootNodeID]?.children.sort(sorter).map((id, i) => (
                <Node key={i} nodeID={id} level={0} />
            ))}
        </TreeWrapper>
    ) : null;
};

const SelectorIcon = styled.img`
    margin-right: 4px;
    cursor: pointer;
    width: 13px;
    height: 13px;
`;

const Clickable = styled.span`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
`;

const nodeTextStyles = css`
    line-height: 140%;
    margin-right: 2px;
`;

const SubtreeWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: ${props => props.indent}px;

    span {
        ${nodeTextStyles};
        cursor: pointer;
    }
`;

const Expander = styled.span`
    padding: 0 4px;
    cursor: pointer;
    min-width: fit-content;
`;

const LeafNodeWrapper = styled.div`
    padding-left: ${props => props.indent}px;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
        ${nodeTextStyles};
    }
    ${props => props.selected && "background-color: #cbeed8;"};

    ${props =>
        props.selectable &&
        `
        :hover {
            background-color: lightblue;
        }
    `};
`;

const LeafNodeEntry = styled.span`
    ${props =>
        props.selectable &&
        `
        cursor: pointer;
    `};
`;

const NodeWrapper = styled.div`
    text-wrap: nowrap;
`;

const TreeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 8px;
    width: 100%;
    overflow-x: hidden;
`;

export default DirectoryTree;
