import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import TextareaAutosize from "react-textarea-autosize";

import {
    useFlowGraphStore,
    useFlowGraphStoreMethods,
    useParameter,
    useParameterDisabledCheck,
} from "@mirinae/hyperflow/modules/stores/flowgraph";
import UI from "@mirinae/hyperflow/components/ui/widgets";
import { CSSSelectorsValue } from "@mirinae/classes/DataValue";

const CSSSelectors = ({ step, paramUI, setLocked }) => {
    const { setParameter, closeParamUI } = useFlowGraphStoreMethods();
    const parameter = useParameter(step, paramUI.pathName);
    const disabled = useParameterDisabledCheck(paramUI);
    const editorRef = useRef();

    const text = parameter?.value || paramUI.defaultValue?.value;

    useEffect(() => {
        if (editorRef.current) {
            const handleTab = event => {
                if (event.key === "Tab") {
                    event.preventDefault();
                    const tabSpaces = "  "; // tab = 4 spaces
                    const start = editorRef.current.selectionStart;
                    const end = editorRef.current.selectionEnd;
                    const text = editorRef.current.value;
                    editorRef.current.value = text.substring(0, start) + tabSpaces + text.substring(end);
                    editorRef.current.selectionStart = editorRef.current.selectionEnd = start + tabSpaces.length;
                }
            };

            editorRef.current.addEventListener("keydown", handleTab);
            return () => {
                if (editorRef.current) editorRef.current.removeEventListener("keydown", handleTab);
            };
        }
    }, [step, paramUI]);

    // hey!! do some CSS selector validation!!!

    const setCSSSelectorsParam = e => {
        setParameter(paramUI.pathName, new CSSSelectorsValue(e.target.value, paramUI.locked, paramUI.label));
        if (paramUI.locked) {
            closeParamUI(paramUI, true);
        }
    };

    return (
        <UI.FieldBox title={paramUI.label} setLocked={setLocked} locked={paramUI.locked} popupEditor>
            {paramUI.closed ? (
                <ClosedCSS>{parameter.displayValue()}</ClosedCSS>
            ) : (
                <CSSSelectorsEditor
                    ref={editorRef}
                    disabled={disabled}
                    defaultValue={text}
                    maxRows={6}
                    minRows={4}
                    onBlur={setCSSSelectorsParam}
                />
            )}
        </UI.FieldBox>
    );
};

const CSSSelectorsEditor = styled(TextareaAutosize)`
    border: none;
    // margin-left: 25px;
    font-family:
        DM Sans,
        serif;
    font-size: 12px;
    letter-spacing: 0.4px;
    padding: 5px 7px;
    resize: none;
    margin-bottom: -3px;
    width: 100%;
    box-sizing: border-box;

    :focus {
        outline: none;
    }
`;

const ClosedCSS = styled.div`
    font-family:
        DM Sans,
        serif;
    font-size: 12px;
    letter-spacing: 0.4px;
    padding: 5px 7px;
    resize: none;
    margin-bottom: -3px;
    width: 100%;
    box-sizing: border-box;
    white-space: pre;
    text-align: left;
`;

const CSSFieldBox = styled(UI.FieldBox)`
    width: 200px;
    text-align: left;
    white-space: pre;
    font-family: monospace;
    font-size: 13px;
`;

export default CSSSelectors;
