import React, { useRef, useState, useEffect, useMemo } from "react";
import styled from "styled-components/macro";
import { useImmer } from "use-immer";

import { useFlowGraphStore, useFlowGraphStoreMethods, useParameter } from "@mirinae/hyperflow/modules/stores/flowgraph";
import { SimpleButton } from "@mirinae/hyperflow/components/ui/widgets";
import { getByPath } from "@mirinae/shared/modules/utils/pathUtils";
import { MatchSpecsValue, PromptValue } from "@mirinae/classes/DataValue";
import flowgraphEngine from "@mirinae/hyperflow/modules/engines/flowgraph";
import { TextEditor } from "@hyperflow/components/hyperflow/parameters/TextEntry";
import UI from "@mirinae/hyperflow/components/ui/widgets";
import cloneDeep from "lodash.clonedeep";
import { useViewerStore, useViewerStoreMethods } from "@hyperflow/modules/stores/viewer";

import objectDeepCompare from "object-deep-compare";
const deepCompare = (a, b) => a && b && objectDeepCompare.CompareValuesWithConflicts(a, b).length === 0;

const initSpec = {
    mode: "matchAndSelect", // from paramUI.mode: 'matchAndSelect', 'matchAndBranch', 'matchAndMessage', 'matchAndButtons', 'userPrompts'
    match: "All", // 'All', 'First' - only for matchAndSelect
    defaultChoice: "",
    choices: [
        { pattern: "foo(.*)", output: "$1.baz" }, // 'matchAndSelect' mode
        { pattern: "foo(.*)", label: "$1.baz", edgeIndex: 0 }, // 'matchAndBranch' mode
        { pattern: "foo(.*)", label: "Option $1", prompt: { text: "explain $1 in greater detail" } }, // 'matchAndButtons' mode
        { pattern: "foo(.*)", message: "You shoud not ask about $1" }, // 'matchAndMessage mode
        { label: "Choose me", "promtp.text": "Explain everything in greater detail" }, // 'userPrompts' mode
    ],
};

const initChoices = {
    matchAndSelect: { pattern: "", output: "" },
    matchAndBranch: { pattern: "", label: "", edgeIndex: 0 },
    matchAndMessage: { pattern: "", message: "" },
    matchAndButtons: { pattern: "", label: "", prompTextt: { text: "" } },
    promptButtons: { label: "", prompt: { text: "" } },
};

const columnLabels = {
    matchAndSelect: ["Pattern", "Output"],
    matchAndBranch: ["Pattern", "Exit path"],
    matchAndMessage: ["Pattern", "Message"],
    matchAndButtons: ["Pattern", "Prompt button", "Prompt text"],
    promptButtons: ["Label", "Prompt text"],
};

const MatchAndSelect = ({ paramUI, choice, choiceIndex, invalid, setProp }) => (
    <>
        <td>
            <Pattern
                defaultValue={choice.pattern}
                invalid={invalid}
                disabled={paramUI.locked}
                onBlur={e => setProp(e, "pattern", choice, choiceIndex)}
            />
        </td>
        <td>
            <OutputText defaultValue={choice.output} disabled={paramUI.locked} onBlur={e => setProp(e, "output", choice, choiceIndex)} />
        </td>
    </>
);

const MatchAndBranch = ({ paramUI, choice, choiceIndex, invalid, setProp }) => (
    <>
        <td>
            <Pattern
                defaultValue={choice.pattern}
                invalid={invalid}
                disabled={paramUI.locked}
                onBlur={e => setProp(e, "pattern", choice, choiceIndex)}
            />
        </td>
        <td style={{ whitSspace: "nowrap", width: "auto", verticalAlign: "top" }}>
            <span>Path {choiceIndex + 1}</span>
        </td>
        {/*<td><LabelText defaultValue={choice.label} disabled={paramUI.locked} onBlur={e => setProp(e, 'label', choice, choiceIndex)} /></td>*/}
    </>
);

const MatchAndMessage = ({ paramUI, choice, choiceIndex, invalid, setProp }) => (
    <>
        <td>
            <Pattern
                defaultValue={choice.pattern}
                invalid={invalid}
                disabled={paramUI.locked}
                onBlur={e => setProp(e, "pattern", choice, choiceIndex)}
            />
        </td>
        <td>
            <MessageText defaultValue={choice.message} disabled={paramUI.locked} onBlur={e => setProp(e, "message", choice, choiceIndex)} />
        </td>
    </>
);

const MatchAndPrompt = ({ paramUI, choice, choiceIndex, invalid, setProp }) => (
    <>
        <td>
            <Pattern
                defaultValue={choice.pattern}
                invalid={invalid}
                disabled={paramUI.locked}
                onBlur={e => setProp(e, "pattern", choice, choiceIndex)}
            />
        </td>
        <td>
            <LabelText defaultValue={choice.label} disabled={paramUI.locked} onBlur={e => setProp(e, "label", choice, choiceIndex)} />
        </td>
        <td>
            <PromptText
                defaultValue={choice.promptText}
                disabled={paramUI.locked}
                onBlur={e => setProp(e, "promptText", choice, choiceIndex)}
            />
        </td>
    </>
);

const PromptButtons = ({ paramUI, choice, choiceIndex, invalid, setProp }) => (
    <>
        <td width="33%" style={{ minWidth: 50 }}>
            <LabelText defaultValue={choice.label} disabled={paramUI.locked} onBlur={e => setProp(e, "label", choice, choiceIndex)} />
        </td>
        <td>
            <PromptText
                defaultValue={choice.promptText}
                disabled={paramUI.locked}
                onBlur={e => setProp(e, "promptText", choice, choiceIndex)}
            />
        </td>
    </>
);

const Match = ({ flow, mode, step, paramUI, setLocked }) => {
    const [spec, setSpec] = useImmer({});
    const [disabled, setDisabled] = useImmer([]);
    const [invalid, setInvalid] = useImmer([]);
    const parameters = useFlowGraphStore(state => state.flow.steps[step.index]?.parameters);
    const parameter = useParameter(step, paramUI.pathName);
    const focusedNode = useViewerStore(state => state.viewer.focusedNode);
    const node = useFlowGraphStore(state => state.flow.flowGraph.nodes[step.nodeID]);
    const { setParameter, setEdgeHighlight, updateParameterSpec, updateExits } = useFlowGraphStoreMethods();
    const { setFocusedNode } = useViewerStoreMethods();
    const specRef = useRef();

    useEffect(
        () =>
            setSpec(state => {
                if (!specRef.current) {
                    const spec = parameter?.value || {
                        ...cloneDeep(initSpec),
                        mode: paramUI.mode,
                        choices: [cloneDeep(initChoices[paramUI.mode])],
                    };
                    console.log("initializing spec state", spec);
                    state.mode = spec.mode;
                    state.match = spec.match;
                    state.choices = spec.choices;
                    specRef.current = cloneDeep(spec);
                }
            }),
        [step]
    );

    useEffect(() => {
        return () => {
            // unmount during configure & selected node dropped => effective blur so save current spec to parameter value
            console.log(useViewerStore.getState().viewer.focusedNode);
            console.log(focusedNode);

            if (
                mode === "configuring" &&
                !useViewerStore.getState().viewer.focusedNode &&
                !deepCompare(specRef.current, parameter?.value)
            ) {
                setParameter(paramUI.pathName, new MatchSpecsValue(specRef.current, parameter?.locked));
            }
        };
    }, [parameter, paramUI, spec, focusedNode]); // [spec, parameter]);

    const onSelectChoice = (choice, e) => {
        if (choice.runPreview) {
            flowgraphEngine.runPreview();
        } else {
            setParameter(paramUI.pathName, new MatchSpecsValue(choice.edgeIndex, false, choice.label));
            const nodeID = flow.currentNodeID;
            const edgeID = flow.flowGraph.nodes[nodeID]?.exits[choice.edgeIndex].edgeID;
            if (edgeID !== undefined) setEdgeHighlight(nodeID, edgeID, false);
        }
    };

    const duplicateChoice = i => {
        // update spec.choices paramSpec
        let choicesUpdated = [...spec.choices];
        choicesUpdated.splice(i + 1, 0, cloneDeep(spec.choices[i]));
        if (spec.mode === "matchAndBranch") {
            // handle matchAndBranch updates
            choicesUpdated = choicesUpdated.map((b, i) => ({ ...b, edgeIndex: i }));
            // update node exit details
            const updatedExits = [...node.exits];
            updatedExits.splice(i + 1, 0, { ...node.exits[i], edgeID: null, label: `${node.exits[i]} copy` });
            updateExits(flow.currentNodeID, updatedExits);
        }
        setSpec(state => {
            state.choices = choicesUpdated;
        });
        specRef.current = { ...specRef.current, choices: choicesUpdated };
    };

    const deleteChoice = i => {
        if (spec.choices.length > 1) {
            // update spec.choices paramSpec
            let choicesUpdated = [...spec.choices];
            choicesUpdated.splice(i, 1);
            if (spec.mode === "matchAndBranch") {
                choicesUpdated = choicesUpdated.map((b, i) => ({ ...b, edgeIndex: i }));
                // update node exit details
                const updatedExits = [...node.exits];
                updatedExits.splice(i, 1);
                updateExits(flow.currentNodeID, updatedExits);
            }
            setSpec(state => {
                state.choices = choicesUpdated;
            });
            specRef.current = { ...specRef.current, choices: choicesUpdated };
        }
    };

    const editDone = () => {
        setParameter(paramUI.pathName, new MatchSpecsValue(spec, paramUI.locked));
        if (flow.runState.startsWith("configuring")) setFocusedNode(null);
    };

    const setProp = (e, prop, choice, choiceIndex) => {
        const value = e.target.value.trim();
        console.log("setProp", prop, value);
        if (prop === "pattern") {
            // ensure valid regexp first
            try {
                const m = value.match(/^\/(.*?)\/([igm]*)/); // allow options literal regexps
                const patString = m ? m[1] : value;
                const flags = m ? m[2] : undefined;
                new RegExp(patString, flags);
                setInvalid(invalid => {
                    invalid[choiceIndex] = false;
                });
            } catch (e) {
                setInvalid(invalid => {
                    invalid[choiceIndex] = true;
                });
            }
        }
        if (!invalid[choiceIndex]) {
            setSpec(state => {
                state.choices[choiceIndex][prop] = value;
            });
            const choicesUpdated = specRef.current.choices;
            choicesUpdated[choiceIndex][prop] = e.target.value;
            specRef.current = { ...specRef.current, choices: choicesUpdated };
        }
    };

    const EditorForMode = useMemo(
        () =>
            ({
                matchAndSelect: MatchAndSelect,
                matchAndBranch: MatchAndBranch,
                matchAndMessage: MatchAndMessage,
                matchAndButtons: MatchAndPrompt,
                promptButtons: PromptButtons,
            })[paramUI.mode],
        [paramUI.mode]
    );

    const title = useMemo(
        () =>
            ({
                matchAndSelect: "Match & select",
                matchAndBranch: "Match & branch",
                matchAndMessage: "Match & message",
                matchAndButtons: "Match & prompt buttons",
                promptButtons: "Prompt buttons",
            })[spec.mode],
        [spec.mode]
    );

    return mode === "configuring" ? (
        <UI.FieldBox
            title={title}
            setLocked={setLocked}
            locked={paramUI.locked}
            popupEditor
            style={{ display: "flex", flexDirection: "column" }}
        >
            <ChoicesTable>
                <Header>
                    <ColumnLabels>
                        {columnLabels[paramUI.mode].map(label => (
                            <ColumnLabel key={label}>{label}</ColumnLabel>
                        ))}
                    </ColumnLabels>
                </Header>
                <Choices disabled={paramUI.locked}>
                    <>
                        {spec.choices?.map((choice, i) => (
                            <Choice key={i}>
                                <EditorForMode choice={choice} choiceIndex={i} paramUI={paramUI} setProp={setProp} invalid={invalid} />
                                <Tool width="20px">
                                    <img src="/assets/images/duplicate.svg" onClick={() => duplicateChoice(i)} />
                                </Tool>
                                <Tool width="20px">
                                    {i !== 0 && <img src="/assets/images/delete.svg" onClick={() => deleteChoice(i)} />}
                                </Tool>
                            </Choice>
                        ))}
                        {!["promptButtons", "matchAndButtons"].includes(spec.mode) && (
                            <>
                                <td style={{ textAlign: "right", fontWeight: 600, paddingRight: 4 }}>Default</td>
                                <td>
                                    {spec.mode === "matchAndBranch" ? (
                                        <span>Path {spec.choices.length + 1}</span>
                                    ) : (
                                        <DefaultChoice
                                            defaultValue={spec.default}
                                            disabled={paramUI.locked}
                                            onBlur={e => {
                                                setSpec(state => {
                                                    state.default = e.target.value;
                                                });
                                                specRef.current = { ...specRef.current, default: e.target.value };
                                            }}
                                        />
                                    )}
                                </td>
                            </>
                        )}
                    </>
                </Choices>
            </ChoicesTable>
            {/*{ !paramUI.locked && <EditDoneButton disabled={() => invalid.some(inv => inv)} onClick={editDone}>OK</EditDoneButton> }*/}
        </UI.FieldBox>
    ) : (
        mode === "historical" && (
            <UI.FieldBox title={title} style={{ display: "flex", flexDirection: "column", transform: "scale (0.9)" }}>
                <ChoicesTable>
                    <Header>
                        <ColumnLabels>
                            {columnLabels[paramUI.mode].map(label => (
                                <ColumnLabel key={label}>{label}</ColumnLabel>
                            ))}
                        </ColumnLabels>
                    </Header>
                    <Choices mode={mode} disabled>
                        {spec.choices?.map((choice, i) => (
                            <Choice key={i}>
                                <EditorForMode choice={choice} choiceIndex={i} paramUI={paramUI} setProp={setProp} invalid={invalid} />
                            </Choice>
                        ))}
                    </Choices>
                </ChoicesTable>
            </UI.FieldBox>
        )
    );
};

const ChoicesTable = styled.table`
    border-collapse: collapse;
    width: 100%;
    height: fit-content;
    //border: thin solid #e0e0e0;
`;

const Header = styled.thead`
    th {
        //border: thin solid #efefef;
        text-align: left;
        padding: 0px 6px;
        font-size: 11px;
    }
`;

const ColumnLabels = styled.tr`
    height: 1.6em;
`;

const ColumnLabel = styled.th`
    font-size: 10px;
    text-align: center;
`;

const Choices = styled.tbody`
    vertical-align: text-top;
    textarea {
        ${props => props.disabled && `background-color: ${props.mode === "historical" ? "#f1f4f3" : "#fcfcfc"};`}
        ${props => props.mode === "historical" && "height: 18px;"}
    }
    tr {
        ${props => props.mode === "historical" && "margin: 0; height: 90%"}
    }
`;

const ChoiceButton = styled(SimpleButton)`
    width: 100%;
    min-width: 106px;
    font-weight: 400;
`;

const Choice = styled.tr`
    margin: 4px 0;
    td {
        padding: 5px;
        vertical-align: middle;
    }
`;

const Tool = styled.td`
    padding: 3px 0px 3px 0px !important;
    cursor: pointer;
    ${props => props.$hidden && "visbility: hidden;"}
`;

const ChoiceButtonLabel = styled(TextEditor)`
    padding: 4px 12px;
    margin-right: 7px;
    border: thin solid #cbcbcb;
    border-radius: 5px;
    font-weight: 600;
    box-shadow:
        rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
`;

const MatchTextEditor = styled(TextEditor)`
    border: thin solid lightgray;
`;

const Pattern = styled(MatchTextEditor)`
    border: thin solid lightgray;
`;

const OutputText = styled(MatchTextEditor)`
    //width: 250px;
    border: thin solid lightgray;
`;

const DefaultChoice = styled(OutputText)``;

const LabelText = styled(ChoiceButtonLabel)`
    text-align: center;
`;

const MessageText = styled(MatchTextEditor)``;

const PromptText = styled(MatchTextEditor)`
    //width: 250px;
    border: thin solid lightgray;
`;

const EditDoneButton = styled.div`
    width: fit-content;
    align-self: center;
    padding: 4px 17px;
    border: thin solid #cbcbcb;
    border-radius: 5px;
    box-shadow:
        rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    cursor: pointer;

    :hover {
        box-shadow:
            0 3px 1px -2px rgb(0 0 0 / 20%),
            0 2px 2px 0 rgb(0 0 0 / 14%),
            0 1px 5px 0 rgb(0 0 0 / 12%);
    }
`;

export default Match;
