import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components/macro";

import {
    useFlowGraphStore,
    useFlowGraphStoreMethods,
    useParameter,
    useParameterDisabledCheck,
} from "@mirinae/hyperflow/modules/stores/flowgraph";
import UI from "@mirinae/hyperflow/components/ui/widgets";
import { CounterValue } from "@mirinae/classes/DataValue";

// hey!! this is a bit of a hack, a single-purpose counter that has to fiddle the node input structures as the counter changes, and is
//  basically a copy of Counter.js except for that fiddling.   There's no obvious way to specialize Counter.js at the moment, try to find one,
//  perhaps pass in the paramSpec a template of the target DataValue subclass and ask a specialized method on it to handle counter-change postprocessing??
const MergeInputs = ({ step, paramUI, mode, setLocked }) => {
    const { setParameter, closeParamUI, setInputs, setOutputs } = useFlowGraphStoreMethods();
    const parameter = useParameter(step, paramUI.pathName);
    const inputType = useParameter(step, paramUI.typePathName);
    const node = useFlowGraphStore(state => state.flow.flowGraph.nodes[step.nodeID]);
    const disabled = useParameterDisabledCheck(paramUI);
    const [invalid, setInvalid] = useState(false);
    const spinnerRef = useRef();

    const count = parameter?.value || paramUI.defaultValue?.value || 2;

    const setCounterParam = e => {
        if (!invalid) {
            const value =
                !e.target.value || e.target.value === paramUI.defaultValue?.value ? paramUI.defaultValue?.value : Number(e.target.value);
            setParameter(paramUI.pathName, new CounterValue(value, paramUI.locked, paramUI.label));
            if (paramUI.locked) {
                closeParamUI(paramUI, true);
            }
        }
    };

    const rebuildInputs = numInputs => {
        if (mode === "configuring") {
            const inputs = Array.from({ length: numInputs || count }, (_, i) => {
                if (node.inputs[i]) {
                    return { ...node.inputs[i], type: inputType.value };
                } else {
                    return {
                        pathName: `input${i + 1}`,
                        type: inputType.value,
                        edgeID: null,
                        label: `Input ${i + 1}`,
                        source: "data",
                        mergeable: true,
                        notes: "connect to any node supplying text or numbers",
                    };
                }
            }).concat(node.inputs.filter(inp => inp.source === "parameter"));
            setInputs(node.nodeID, inputs);
            setOutputs(node.nodeID, [{ ...node.outputs[0], type: inputType.value }]);
        }
    };

    useEffect(() => {
        const dataInputs = node.inputs.filter(inp => inp.source === "data");
        if (dataInputs.length !== count || dataInputs[0].type !== inputType.value) {
            rebuildInputs();
        }
    }, [inputType]);

    const counterChanged = e => {
        const count = Number(e.target.value);
        const isInValid = Number.isNaN(count) && e.target.value !== paramUI.defaultValue?.value;
        setInvalid(isInValid);
        if (!isInValid) {
            rebuildInputs(count);
        }
    };

    return (
        <UI.FieldBox title={paramUI.label} setLocked={setLocked} locked={paramUI.locked}>
            {paramUI.closed ? (
                parameter.displayValue()
            ) : (
                <CountSpinner
                    type="number"
                    min={paramUI.minValue || 0}
                    max={paramUI.maxValue || 100}
                    step={paramUI.stepValue || 1}
                    ref={spinnerRef}
                    disabled={disabled}
                    $invalid={invalid}
                    defaultValue={count}
                    minRows={1}
                    onChange={counterChanged}
                    onBlur={setCounterParam}
                />
            )}
        </UI.FieldBox>
    );
};

export const CountSpinner = styled.input`
    border: none;
    // margin-left: 25px;
    font-family:
        DM Sans,
        serif;
    font-size: 14px;
    // letter-spacing: 0.4px;
    text-align: right;
    padding: 5px 7px;
    margin-bottom: -3px;
    resize: none;
    min-width: 120px;
    width: 100%;
    box-sizing: border-box;
    ${props => props.$invalid && "color: red;"};

    :focus {
        outline: none;
    }
`;

export default MergeInputs;
