import React, { useRef, useMemo, useEffect } from "react";
import styled from "styled-components/macro";
import TextareaAutosize from "react-textarea-autosize";
import beautify from "json-beautify";

import { useFlowGraphStoreMethods, useParameter, useParameterDisabledCheck } from "@mirinae/hyperflow/modules/stores/flowgraph";
import UI from "@mirinae/hyperflow/components/ui/widgets";
import { PromptTemplateValue } from "@mirinae/classes/DataValue";

const defaultTemplate = `
[
    {
        "type": "merged",
        "pathName": "instructions.text",
        "contextType": "instructions",
        "range": "last:1",
        "maxTokens": 1000
    },
    {
        "type": "interleaved",
        "range": "last:3",
        "maxTokens": 7000,
        "fields": [
            {
                "pathName": "knowledge.instructions.text",
                "contextType": "knowledgeInstructions",
                "range": "last:1",
                "maxTokens": 1000
            },
            {
                "pathName": "knowledge",
                "contextType": "knowledge",
                "range": "last:1",
                "header": "---\\n**Knowledge Segments:**",
                "footer": "\\n---",
                "structured": true
            },
            { "pathName": "content", "contextType": "user", "ignoreTokens": true },
            { "pathName": "prompt.text", "contextType": "user", "header": "**User Query:**" },
            { "pathName": "generation.text", "contextType": "generator", "maxTokens": 3000 }
        ]
    }
]
`;

// see original default versions at the end of this module

const PromptTemplate = ({ flow, step, paramUI, setLocked }) => {
    const { setParameter, closeParamUI } = useFlowGraphStoreMethods();
    const parameter = useParameter(step, paramUI.pathName);
    const disabled = useParameterDisabledCheck(paramUI);
    const editorRef = useRef();

    const templateAsText = useMemo(() => (parameter ? beautify(parameter.value, null, 2, 100) : defaultTemplate), [parameter]);

    useEffect(() => {
        if (editorRef.current) {
            const handleTab = event => {
                if (event.key === "Tab") {
                    event.preventDefault();
                    const tabSpaces = "  "; // tab = 4 spaces
                    const start = editorRef.current.selectionStart;
                    const end = editorRef.current.selectionEnd;
                    const text = editorRef.current.value;
                    editorRef.current.value = text.substring(0, start) + tabSpaces + text.substring(end);
                    editorRef.current.selectionStart = editorRef.current.selectionEnd = start + tabSpaces.length;
                }
            };

            editorRef.current.addEventListener("keydown", handleTab);
            return () => {
                if (editorRef.current) editorRef.current.removeEventListener("keydown", handleTab);
            };
        }
    }, [step, paramUI]);

    const setTemplateParam = e => {
        try {
            const template = JSON.parse(editorRef.current.value || defaultTemplate);
            setParameter(paramUI.pathName, new PromptTemplateValue(template, paramUI.locked, paramUI.label));
        } catch (e) {
            alert(e.message); // hey!!, put up a proper alert or something
        }
        // const templateAsArray = editorRef.current.value.split('\n');
        // setParameter(paramUI.pathName, new PromptTemplateValue(templateAsArray, paramUI.locked, paramUI.label));
        if (paramUI.locked) {
            closeParamUI(paramUI, true);
        }
    };

    return !paramUI.closed || parameter.value ? (
        <UI.FieldBox
            title={paramUI.label}
            setLocked={setLocked}
            locked={paramUI.locked}
            popupEditor={{ defaultWidth: 700 }}
            accept={!paramUI.closed && setTemplateParam}
            style={{ ...(paramUI.style || {}) }}
        >
            {paramUI.closed ? (
                <ClosedTemplate>{parameter.displayValue()}</ClosedTemplate>
            ) : (
                <TemplateEditor
                    ref={editorRef}
                    spellCheck={false}
                    disabled={disabled}
                    defaultValue={templateAsText}
                    minRows={paramUI.minRows || 3}
                    onBlur={setTemplateParam}
                />
            )}
        </UI.FieldBox>
    ) : null;
};

export const TemplateEditor = styled(TextareaAutosize)`
    border: none;
    // margin-left: 25px;
    // letter-spacing: 0.4px;
    padding: 5px 7px;
    margin-bottom: -3px;
    box-sizing: border-box;
    font-family: monospace;
    font-size: 12px;
    letter-spacing: 0.4px;
    white-space: pre-wrap;
    width: 100%;
    height: 100%;
    text-align: left;
    line-height: 14px;
    overflow: auto;
    resize: none;

    :focus {
        outline: none;
    }
`;

const ClosedTemplate = styled.div`
    text-align: left;
    //max-width: 500px;
    //max-height: 120px;
    height: 100%;
    //width: 100%;
    white-space: pre; /* Preserves whitespace */
    //width: 0; /* Forces the element to start from zero width */
    min-width: 0; /* Allows shrinking */
    flex: 1; /* Allows growing to fill available space */
    overflow-x: hidden; /* Clips overflow */
    color: #767575;
    font-size: 10px;
`;

// original default versions

// [
//     {
//         "type": "merged",
//         "pathName": "instructions.text",
//         "contextType": "instructions",
//         "range": "last:1",
//         "maxTokens": 1000
//     },
//     {
//         "type": "interleaved",
//         "range": "last:3",
//         "maxTokens": 5000,
//         "fields": [
//             {
//                 "pathName": "knowledge.instructions.text",
//                 "contextType": "knowledgeInstructions",
//                 "maxTokens": 1000,
//                 "range": "last:1",
//             },
//             { "pathName": "knowledge", "contextType": "knowledge", "split": true, "structured": true, "range": "last:1" },
//             { "pathName": "prompt.text", "contextType": "user" },
//             { "pathName": "content", "contextType": "user", "ignoreTokens": true },
//             { "pathName": "generation.text", "contextType": "generator", "maxTokens": 3000 }
//         ]
//     }
// ]

export default PromptTemplate;
