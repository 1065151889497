import React, { useState, useEffect, useRef, useCallback } from "react";
import styled, { css } from "styled-components/macro";
import ContentEditable from "react-contenteditable";
import { useImmer } from "use-immer";
import { Rnd } from "react-rnd";

const SelectWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    // align-self: flex-start;
    ${props => !["tight", "tighter"].includes(props.fit) && "margin-top: 8px"};
`;
const ListSelect = styled.select`
    font-size: 11px;
    color: #3c3c3b;
    border-color: #a7a7a7;
    background-color: #ffffff;
    width: 120px;
    border-radius: 5px;
    padding: 2px;
`;

const Label = styled.span`
    font-size: 11px;
    margin: ${props => (props.fit === "tight" ? "0 8px" : props.fit === "tighter" ? "0 8px 0 0" : "8px")};
    min-width: 55px;
    text-align: right;
`;

const fieldBox = css`
    position: relative;
    width: calc(100% - 38px);
    font-size: 12px;
    padding: 12px;
    //white-space: nowrap;
    border: thin solid lightgray;
    border-radius: 5px;
    margin-top: 12px;

    &:focus {
        // background: #f2f7ff;
        //outline-width: 1px;
        //outline-color: lightblue;
        outline: transparent;
    }

    &:before {
        position: absolute;
        left: 15px;
        top: -8px;
        font-size: 11px;
        background-color: white;
        padding: 0 4px;
    }
`;

const EnContentEditable = styled(ContentEditable)`
    padding: 1px 0px 1px 4px;
    width: calc(50% - 35px);
    margin: 4px 4px 4px 12px;
    border-bottom: thin solid lightgray;
    font-weight: 600;

    &:focus {
        //outline-color: lightblue;
        outline: transparent;
    }
`;

const KoContentEditable = styled(EnContentEditable)`
    font-family: "Noto Sans KR";
`;

const AddButton = styled.div`
    stroke: darkgray;
    fill: darkgray;
    transform: scale(0.4);
    position: absolute;
    bottom: -14px;
    right: -11px;
    cursor: pointer;

    &:hover {
        fill: #00c7db;
    }
`;

const TextFieldBox = styled(ContentEditable)`
    ${fieldBox};
`;

const TextInput = styled(ContentEditable)`
    text-align: left;
    outline: transparent;
`;

export const SimpleButton = styled.button`
    padding: 4px 16px;
    border: thin solid #cbcbcb;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    box-shadow:
        rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

    &:hover:not(:disabled) {
        box-shadow:
            rgba(0, 0, 0, 0.16) 0px 3px 6px,
            rgba(0, 0, 0, 0.23) 0px 3px 6px;
    }

    :disabled {
        color: lightgray;
        cursor: default;
        pointer-events: none;
    }
`;

const DeleteButton = styled.div`
    stroke: darkgray;
    fill: darkgray;
    transform: scale(0.35);
    position: absolute;
    bottom: -14px;
    right: -16px;
    cursor: pointer;

    &:hover {
        fill: #00c7db;
    }
`;

// const CFBToggle = styled.span`
//     max-width: 17px;
//     margin-top: 1px;
//     margin-left: -2px;
//     cursor: pointer;
//     fill: #7f7f7f;
// `;

const ExpandIcon = styled.img`
    content: url("/assets/images/fb-expand.svg");
`;

const CollapseIcon = styled.img`
    content: url("/assets/images/fb-collapse.svg");
`;

const EnableIcon = styled.img`
    content: url("/assets/images/add-example.svg");
`;

const CheckboxBox = styled.div`
    display: flex;
    flex-direction: row;
    -webkit-box-pack: center;
    justify-content: flex-start;
    align-items: center;

    span {
        width: fit-content;
        min-width: 106px;
        font-weight: 400;
    }
`;

const Checkbox = styled.input`
    padding-right: 5px;
`;

const CheckBox = ({ label, checked, disabled, onChange }) => (
    <CheckboxBox>
        <Checkbox type="checkbox" defaultChecked={checked} disabled={disabled} onChange={e => onChange(e)} />
        <span>{label}</span>
    </CheckboxBox>
);

const Select = ({ label, selection, options, optGroups, onChange, style, fit }) => (
    <SelectWrapper className="select-wrapper" fit={fit}>
        {label && (
            <Label className="select-label" fit={fit}>
                {label}
            </Label>
        )}
        <ListSelect className="select-select" value={selection} style={style} onChange={onChange}>
            {options &&
                options.map((o, i) => (
                    <option key={i} disabled={o.disabled} value={o.value || o}>
                        {o.label || o}
                    </option>
                ))}
            {optGroups &&
                optGroups.map(og => (
                    <optgroup key={og.group} label={og.group}>
                        {og.options.map((o, i) => (
                            <option key={i} value={JSON.stringify(o.includes)}>
                                {o.label}
                            </option>
                        ))}
                    </optgroup>
                ))}
        </ListSelect>
    </SelectWrapper>
);

const NestedSelectBox = styled.div`
    display: flex;
    flex-direction: column;
    border: thin solid lightgray;
    background: white;
    padding: 4px 6px;
    box-shadow:
        rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    cursor: default;
`;

const NestedSelectMenuItem = styled.div`
    display: flex;
    flex-direction: row;
    padding: 2px 5px;
    width: fit-content;
    ${props =>
        props.disabled &&
        `
        color: #c7c7c7;
        pointer-events: none;
    `};

    :hover {
        background: #e8e8e8;
    }
`;

const NestedSelectNestedMenu = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2px 5px;
    ${props =>
        props.disabled &&
        `
        color: #c7c7c7;
        pointer-events: none;
    `};

    :hover {
        background: #e8e8e8;
    }
`;

const Divider = styled.div`
    width: 100%;
    border-bottom: solid thin lightgray;
    height: 1px;
`;

const NestedSelect = ({ value, onSelect, items, placeHolder, close }) => {
    const [showSubmenu, setShowSubmenu] = useImmer([]);

    const checkOutsideClick = e => {
        if (typeof e.target.className === "string" && e.target.className.indexOf("nested-menu") > 0) return;
        close();
    };

    useEffect(() => {
        document.body.addEventListener("click", checkOutsideClick, false);
        return () => {
            document.body.removeEventListener("click", checkOutsideClick);
        };
    }, [items]);

    const enterSubmenu = i => {
        setShowSubmenu(state => {
            state[i] = true;
        });
    };

    const leaveSubmenu = i => {
        setShowSubmenu(state => {
            state[i] = false;
        });
    };

    const onClickSelection = (e, item) => {
        e.stopPropagation();
        onSelect(item);
        close();
    };

    const onCloseClicked = e => {
        e.stopPropagation();
        close();
    };

    return (
        <NestedSelectBox>
            {items
                .filter(item => item)
                .map((item, i) =>
                    item.submenu ? (
                        <NestedSelectNestedMenu
                            className="nested-menu"
                            key={item.label}
                            disabled={item.disabled}
                            label={item.label}
                            onMouseEnter={() => enterSubmenu(i)}
                            onMouseLeave={() => leaveSubmenu(i)}
                        >
                            {showSubmenu[i] &&
                                item.submenu
                                    .filter(item => item)
                                    .map((subitem, k) => (
                                        <NestedSelectMenuItem
                                            key={subitem.label}
                                            className="nested-menu"
                                            disabled={subitem.disabled}
                                            onClick={onCloseClicked}
                                        >
                                            {subitem.label}
                                        </NestedSelectMenuItem>
                                    ))}
                        </NestedSelectNestedMenu>
                    ) : item.label === "-" ? (
                        <Divider key={i} />
                    ) : (
                        <NestedSelectMenuItem
                            key={i}
                            className="nested-menu"
                            disabled={item.disabled}
                            onClick={e => onClickSelection(e, item)}
                            value={item.value}
                        >
                            {item.label}
                        </NestedSelectMenuItem>
                    )
                )}
        </NestedSelectBox>
    );
};

const OptionalFieldBox = ({ title, startEnabled = false, collapsible = false, children }) => {
    const [enabled, setEnabled] = useState(startEnabled);
    return enabled ? (
        <CollapsingFieldBox title={title} collapsible={collapsible} startExpanded children={children} />
    ) : (
        <OFBWrapper>
            <FBTitle top={-7}>
                {title}
                <OFBEnable onClick={() => setEnabled(!enabled)}>
                    <EnableIcon />
                </OFBEnable>
            </FBTitle>
        </OFBWrapper>
    );
};

// ------- ^^^^ old stuff from ./widgets.js ^^^^ -------

const LoadingSpinner = () => (
    <SpinnerDiv>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </SpinnerDiv>
);
const SpinnerDiv = styled.div`
    display: inline-block;
    position: relative;
    width: 20px;
    box-sizing: border-box;
    height: 20px;

    div {
        box-sizing: border-box;
        position: absolute;
        top: 33.33333px;
        width: 13.33333px;
        height: 13.33333px;
        border-radius: 50%;
        background: currentColor;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    div:nth-child(1) {
        left: 8px;
        animation: lds-ellipsis1 0.6s infinite;
    }
    div:nth-child(2) {
        left: 8px;
        animation: lds-ellipsis2 0.6s infinite;
    }
    div:nth-child(3) {
        left: 32px;
        animation: lds-ellipsis2 0.6s infinite;
    }
    div:nth-child(4) {
        left: 56px;
        animation: lds-ellipsis3 0.6s infinite;
    }
    @keyframes lds-ellipsis1 {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }
    @keyframes lds-ellipsis3 {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }
    @keyframes lds-ellipsis2 {
        0% {
            transform: translate(0, 0);
        }
        100% {
            transform: translate(24px, 0);
        }
    }
`;

const PopupEditor = ({ title, closeEditor, locked, setLocked, lockClicked, popupEditor, toggleRaw, fieldRef, children }) => {
    if (!fieldRef.current) return null;

    const popupRef = useRef(null);

    const bbox = fieldRef.current.getBoundingClientRect();
    const width = popupEditor.defaultWidth || window.innerWidth / 2.5;
    const height = popupEditor.defaultHeight || 560;
    const placement = {
        x: -width - 20,
        y: bbox.top + height > window.innerHeight ? -(bbox.top + height - 8 - window.innerHeight) : 0,
        width,
        height,
    };

    const checkOutsideClick = e => {
        if (e.target === popupRef.current || fieldRef.current?.contains(e.target) || popupRef.current?.contains(e.target)) return;
        closeEditor();
    };

    useEffect(() => {
        document.body.addEventListener("click", checkOutsideClick, false);
        return () => {
            document.body.removeEventListener("click", checkOutsideClick);
        };
    }, [children, fieldRef]);

    return (
        <Rnd
            default={placement}
            minWidth={200}
            minHeight={150}
            dragHandleClassName="editor-popup-handle"
            style={{ position: "fixed", zIndex: 100 }}
        >
            <EditorPopup ref={popupRef} className="editor-popup">
                <TitleBar className="editor-popup-handle">
                    {title}
                    <ToolBox>
                        {toggleRaw && (
                            <FBTool src={true ? "/assets/images/markdown.svg" : "/assets/images/markdown.svg"} onClick={toggleRaw} />
                        )}
                        {setLocked /* && locked !== undefined */ && (
                            <FBTool src={locked ? "/assets/images/lock.svg" : "/assets/images/lock-open.svg"} onClick={lockClicked} />
                        )}
                        <span onClick={closeEditor}></span>
                    </ToolBox>
                </TitleBar>
                <PopupValue style={popupEditor.valueStyle} className={`${locked ? "" : "un"}locked-param`}>
                    {children}
                </PopupValue>
            </EditorPopup>
        </Rnd>
    );
};

const EditorPopup = styled.div`
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 6px;
    height: calc(100% - 25px);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;

const TitleBar = styled.div`
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    font-size: 14px;
    background: rgb(221, 220, 220);
    margin: 0px;
    padding: 4px 10px;
    -webkit-box-pack: justify;
    justify-content: space-between;
    cursor: grab;

    span {
        font-family: Webdings;
        color: #868686;
        font-size: 16px;
        padding: 0 2px 3px 2px;
        cursor: pointer;
    }
`;

const PopupValue = styled.div`
    display: flex;
    flex-direction: column;
    //align-items: center;
    width: 100%;
    height: 100%;
    padding: 14px;
    font-size: 14px;
    overflow: auto;

    .ant-dropdown-menu {
        background-color: white;
    }
`;

const ToolBox = styled.div`
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    gap: 8px;
`;

// -------

const FieldBoxWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    height: fit-content;

    &:focus {
    }

    #editor & {
    }
`;

const FBTitle = styled.div`
    color: ${({ required }) => (false && required ? "#cf4b4b" : "#666669")};
    font-size: 11px;
    display: flex;
    -webkit-box-pack: justify;
    align-items: center;
    justify-content: space-between;
    pointer-events: all;
    //margin: 0 0 1px 1px;
    cursor: ${({ collapsible }) => (collapsible ? "pointer" : "default")};
`;

const ValueRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    //border: thin solid #dcdce0;
    //border-radius: 8px;
    gap: 2px;
    min-width: 0;
    overflow: auto;
`;

const Value = styled.div`
    display: flex;
    border-radius: var(--Corner-Medium, 8px);
    border: 1px solid ${({ required }) => (required ? "#cf4b4b" : "#dedede")}; // #e6e6e6
    width: 100%;
    height: 100%;
    padding: 3px 9px;
    font-size: 13px;
    overflow: hidden;

    flex: 1; /* This allows it to grow and shrink */
    min-width: 0; /* Prevents flex items from overflowing */
    //transition: flex-basis 300ms ease-in-out;

    :hover {
        border-color: #2a96ff;
    }

    .ant-dropdown-menu {
        background-color: white;
    }

    .historical-paramblock & {
        pointer-events: none;
    }
`;

const FBTitleToolBar = styled.div`
    display: flex; // none; // flex;
    align-items: center;
    justify-content: space-between;
    opacity: ${({ showTools }) => (showTools ? 1 : 0)};
    ${({ showTools }) => !showTools && `transition: all 250ms ease-out;`};
    // transition: opacity ${({ showTools }) => (showTools ? "1ms linear" : "100ms ease-out")};
    //transition: all 100ms ease-in-out;
    transform: scale(0.9);
    transform-origin: center;
    //opacity: 0.8;

    img {
        width: 21px;
    }
`;

const FBEndToolBar = styled.div`
    display: none; // flex;
    align-items: flex-start;
    justify-content: space-between;
    opacity: ${({ showTools }) => (showTools ? 1 : 0)};
    transition: opacity ${({ showTools }) => (showTools ? "1ms linear" : "250ms ease-out")};
    //transition: all 100ms ease-in-out;
    align-self: flex-start;
    margin-top: 3px;
    transform: scale(0.9);
    transform-origin: center;

    img {
        width: 21px;
    }
`;

const FBToolColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    //flex-shrink: 0;
    gap: 3px;

    // width: ${({ showTools }) => (showTools ? "21px" : "0")};
    flex-shrink: 0;
    //transition: all 250ms ease-in-out;
    overflow: hidden;

    .historical-paramblock & {
        display: none;
    }
`;

export const FBTool = styled.img`
    /* transform: scale(0.65); */
    border-radius: 6px;
    width: 21px;
    height: 21px;
    padding: 3px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    cursor: pointer;
    /* max-width: 100%; */
    /* max-height: 100%; */
    object-fit: contain;

    &:hover:not(:disabled) {
        background-color: #eeeef2;
    }

    ${({ $checked }) =>
        $checked &&
        `
        background-color: #eeeef2;
        box-shadow:
            inset 0 2px 2px rgba(0, 0, 0, 0.17), /* reduced blur radius */
            inset 0 -2px 2px rgba(255, 255, 255, 0.5); /* reduced blur radius */
    `}

    ${({ disabled }) => disabled && "opacity: 0.3;"}
`;

const CFBToggle = styled.img`
    transform: translate(-5px, 2px);
`;

export const FBAccept = styled.img`
    position: absolute;
    right: 3px;
    top: -12px;
    background-color: rgb(242, 240, 239);
    /* width: 15px; */
    /* height: 15px; */
    cursor: pointer;
    transform: scale(0.54);
`;

const OFBWrapper = styled(FieldBoxWrapper)`
    padding: 7px;
`;

const OFBEnable = styled.span`
    max-width: 17px;
    max-height: 17px;
    margin-top: -8px;
    margin-left: -2px;
    stroke: #7f7f7f;
    fill: #7f7f7f;
    transform: scale(0.25);
    cursor: pointer;
`;

const CollapsingContents = styled(Value)`
    display: flex;
    flex-direction: column;
    padding-bottom: 6px;
    ${({ required }) => required && "border: 1px solid #cf4b4b"};
    overflow: unset;
`;

const CollapsingFieldBox = ({ title, collapsible = true, expanded, setExpanded, required, fieldStyle, locked, children }) => {
    return (
        <FieldBoxWrapper style={fieldStyle}>
            <FBTitle collapsible={collapsible} onClick={setExpanded} top={collapsible ? -14 : -8} locked={locked}>
                {title}
                {collapsible && <CFBToggle src={expanded ? "/assets/images/chevron-up.svg" : "/assets/images/chevron-down.svg"} />}
            </FBTitle>
            <CollapsingContents className={`${locked && !expanded ? "" : "un"}locked-param`} required={!expanded && required}>
                {expanded || !collapsible ? children : "..."}
            </CollapsingContents>
        </FieldBoxWrapper>
    );
};

const FieldBox = ({ title, fieldStyle, collapsible = false, expanded = false, locked, required, setLocked, popupEditor, children }) => {
    const [isExpanded, setExpanded] = useState(expanded);
    const [showEditor, setShowEditor] = useState(false);
    const [showTools, setShowTools] = useState(false);
    const nodeRef = useRef(null);
    const mouseEnterTimer = useRef(null);
    const toolHideTimer = useRef(null);

    const lockClicked = () => {
        const newState = !locked;
        setLocked(newState);
    };

    const toolHider = useCallback(() => setShowTools(false), []);

    const delayedToolHide = () => {
        toolHideTimer.current = setTimeout(() => {
            toolHideTimer.current = null;
            toolHider();
        }, 1000); // 500);
    };

    const editClicked = e => {
        e.stopPropagation();
        setShowEditor(true);
        delayedToolHide();
    };

    const closeEditor = () => {
        setShowEditor(false);
        delayedToolHide();
    };

    const mouseEnter = () => {
        if (toolHideTimer.current) clearTimeout(toolHideTimer.current);
        mouseEnterTimer.current = setTimeout(() => {
            mouseEnterTimer.current = null;
            setShowTools(true);
        }, 250);
    };

    const mouseLeave = () => {
        if (mouseEnterTimer.current) clearTimeout(mouseEnterTimer.current);
        if (toolHideTimer.current) clearTimeout(toolHideTimer.current);
        delayedToolHide();
    };

    return (
        <FieldBoxWrapper ref={nodeRef} style={fieldStyle} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
            <FBTitle top={-8} locked={locked} required={required}>
                {title || <div />}
                {collapsible ? (
                    <CFBToggle src={isExpanded ? "/assets/images/chevron-up.svg" : "/assets/images/chevron-down.svg"} />
                ) : (
                    <FBTitleToolBar showTools={showTools}>
                        {popupEditor && <FBTool src={"/assets/images/popup-editor.svg"} onClick={editClicked} />}
                        {setLocked /* && locked !== undefined */ && (
                            <FBTool src={locked ? "/assets/images/lock.svg" : "/assets/images/lock-open.svg"} onClick={lockClicked} />
                        )}
                    </FBTitleToolBar>
                )}
            </FBTitle>
            {showEditor ? (
                <>
                    <PopupEditor
                        title={title}
                        locked={locked}
                        setLocked={setLocked}
                        lockClicked={lockClicked}
                        closeEditor={closeEditor}
                        popupEditor={popupEditor}
                        fieldRef={nodeRef}
                    >
                        {children}
                    </PopupEditor>
                    ...
                </>
            ) : isExpanded || !collapsible ? (
                <ValueRow>
                    <Value required={required} className={`${locked ? "" : "un"}locked-param`}>
                        {children}
                    </Value>
                    <FBEndToolBar showTools={showTools}>
                        {popupEditor && <FBTool src={"/assets/images/popup-editor.svg"} onClick={editClicked} />}
                        {setLocked /* && locked !== undefined */ && (
                            <FBTool src={locked ? "/assets/images/lock.svg" : "/assets/images/lock-open.svg"} onClick={lockClicked} />
                        )}
                    </FBEndToolBar>
                </ValueRow>
            ) : (
                "..."
            )}
        </FieldBoxWrapper>
    );
};

// ---- session log UI ------

export const StepContentBlock = ({
    title,
    popupReader,
    expander,
    expanded = true,
    toggleRaw,
    readerChildren,
    children,
    bgColor,
    borderColor,
}) => {
    const [isExpanded, setExpanded] = useState(expanded);
    const [showReader, setShowReader] = useState(false);
    const [showTools, setShowTools] = useState(false);
    const mouseEnterTimer = useRef(null);
    const toolHideTimer = useRef(null);
    const nodeRef = useRef(null);

    const toggleExpansion = () => setExpanded(!isExpanded);

    const toolShower = useCallback(() => setShowTools(true), []);
    const toolHider = useCallback(() => setShowTools(false), []);

    const delayedToolHide = () => {
        toolHideTimer.current = setTimeout(() => {
            toolHideTimer.current = null;
            toolHider();
        }, 1000);
    };

    const openReader = e => {
        // e.stopPropagation();
        setShowReader(true);
    };

    const closeReader = () => {
        setShowReader(false);
        delayedToolHide();
    };

    const mouseEnter = () => {
        if (toolHideTimer.current) clearTimeout(toolHideTimer.current);
        mouseEnterTimer.current = setTimeout(() => {
            mouseEnterTimer.current = null;
            toolShower();
        }, 250);
    };

    const mouseLeave = () => {
        if (mouseEnterTimer.current) clearTimeout(mouseEnterTimer.current);
        if (toolHideTimer.current) clearTimeout(toolHideTimer.current);
        delayedToolHide();
    };

    return (
        <StepContentBlockWrapper
            ref={nodeRef}
            bgColor={bgColor}
            borderColor={borderColor}
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
        >
            {title && (
                <StepContentTitle>
                    {title}
                    <StepContentTools>
                        {showTools && popupReader && <img src={"/assets/images/popup-editor.svg"} onClick={openReader} alt="reader" />}
                        {expander && (
                            <img
                                src={`/assets/images/chevron-${isExpanded ? "up" : "down"}.svg`}
                                onClick={toggleExpansion}
                                alt="expander"
                            />
                        )}
                    </StepContentTools>
                </StepContentTitle>
            )}
            {(!expander || isExpanded) && (
                <>
                    {showReader && (
                        <PopupEditor
                            title={title}
                            closeEditor={closeReader}
                            toggleRaw={toggleRaw}
                            popupEditor={popupReader}
                            fieldRef={nodeRef}
                        >
                            {readerChildren || children}
                        </PopupEditor>
                    )}
                    {children}
                </>
            )}
        </StepContentBlockWrapper>
    );
};

export const StepContentTools = styled.div`
    display: flex;
    align-items: center;
    pointer-events: all;
`;

const StepContentBlockWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8px;
    border-radius: 8px 8px 0px 0px;
    // background: ${({ bgColor }) => bgColor || "#fbfbfb"};
    border: thin solid ${({ borderColor }) => borderColor || "#c4c4c4"};
    gap: 4px;
    overflow-y: auto;

    .locked-param {
        background-color: #f4f4f4;
        color: #646464;
    }
    .unlocked-param {
        background-color: white;
    }

    .param-panel & {
        padding: 0;
        border: none;
        overflow: unset;
    }
`;

const StepContentTitle = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: 20px;

    color: var(--Neutral-Text-color-text-primary, #1a1a1b);
    font-size: var(--Label-Small-Size, 11px);
    font-weight: 500;
    line-height: var(--Label-Small-Line-Height, 16px); /* 145.455% */
    letter-spacing: var(--Label-Small-Tracking, 0.5px);

    img {
        cursor: pointer;
        width: 15px;
    }
`;

//     display: flex;
//     justify-content: space-between;

export default {
    Select,
    fieldBox,
    AddButton,
    DeleteButton,
    TextFieldBox,
    TextInput,
    CollapsingFieldBox,
    OptionalFieldBox,
    FieldBox,
    EnContentEditable,
    KoContentEditable,
    SelectWrapper,
    Label,
    SimpleButton,
    NestedSelect,
    CheckBox,
    PopupEditor,
    LoadingSpinner,
    StepContentBlock,
    StepContentTitle,
    StepContentTools,
};
