import React, { useRef, useState, useEffect, useMemo } from "react";
import styled from "styled-components/macro";
import { useImmer } from "use-immer";

import { useFlowGraphStore, useFlowGraphStoreMethods, useParameter } from "@mirinae/hyperflow/modules/stores/flowgraph";
import { SimpleButton } from "@mirinae/hyperflow/components/ui/widgets";
import { getByPath } from "@mirinae/shared/modules/utils/pathUtils";
import { MatchSpecsValue, PromptValue } from "@mirinae/classes/DataValue";
import flowgraphEngine from "@mirinae/hyperflow/modules/engines/flowgraph";
import { TextEditor } from "@hyperflow/components/hyperflow/parameters/TextEntry";
import UI from "@mirinae/hyperflow/components/ui/widgets";
import cloneDeep from "lodash.clonedeep";
import { useViewerStore, useViewerStoreMethods } from "@hyperflow/modules/stores/viewer";

import objectDeepCompare from "object-deep-compare";
import { NumberEditor } from "@hyperflow/components/hyperflow/parameters/NumberEntry";
const deepCompare = (a, b) => a && b && objectDeepCompare.CompareValuesWithConflicts(a, b).length === 0;

// the idea is that this would be a loop control node in a graph, situated typically at end of a flow with its exit edge
// recursing back to the start of the loop.   It may be possible to have multiple loops each with their own loop-control node, conditionally
// followed in the body of the main flow, though initially there should only be one (for multiple, each would have to ensure there is no
// conflict with other control nodes)
// the control nodes inspect the entire flow, looking for loop-controlled nodes, and presenting a compound parameter UI offering various controls
// over how these nodes deliver their data supply during loops.  Some initial controls would be:
//   - nested or parallel delivery if there are multiple data-sourcing nodes
//   - end-of-data behavior if loop continues: repeat data loop from the beginning, repeat final value
//   - batch delivery for nodes that can supplying multi-value selections or sets - how many per batch

// the following is cribbed from Match.js, not at all complete

const initSpec = {
    maxLoops: "unlimited",
    controlledNodes: [],
};

const initChoices = {
    matchAndSelect: { pattern: "", output: "" },
    matchAndBranch: { pattern: "", label: "", edgeIndex: 0 },
    matchAndMessage: { pattern: "", message: "" },
    matchAndButtons: { pattern: "", label: "", prompTextt: { text: "" } },
    promptButtons: { label: "", prompt: { text: "" } },
};

const columnLabels = {
    matchAndSelect: ["Pattern", "Output"],
    matchAndBranch: ["Pattern", "Exit path"],
    matchAndMessage: ["Pattern", "Message"],
    matchAndButtons: ["Pattern", "Prompt button", "Prompt text"],
    promptButtons: ["Label", "Prompt text"],
};

const MatchAndBranch = ({ paramUI, choice, choiceIndex, invalid, setProp }) => (
    <>
        <td>
            <Pattern
                defaultValue={choice.pattern}
                invalid={invalid}
                disabled={paramUI.locked}
                onBlur={e => setProp(e, "pattern", choice, choiceIndex)}
            />
        </td>
        <td>
            <span>Path {choiceIndex + 1}</span>
        </td>
        {/*<td><LabelText defaultValue={choice.label} disabled={paramUI.locked} onBlur={e => setProp(e, 'label', choice, choiceIndex)} /></td>*/}
    </>
);

const LoopControl = ({ flow, mode, step, paramUI, setLocked }) => {
    const [loopControl, setLoopControl] = useImmer({});
    const [disabled, setDisabled] = useImmer([]);
    const [invalid, setInvalid] = useImmer([]);
    const parameters = useFlowGraphStore(state => state.flow.steps[step.index]?.parameters);
    const parameter = useParameter(step, paramUI.pathName);
    const focusedNode = useViewerStore(state => state.viewer.focusedNode);
    const node = useFlowGraphStore(state => state.flow.flowGraph.nodes[step.nodeID]);
    const { setParameter, setEdgeHighlight, updateParameterSpec, updateExits } = useFlowGraphStoreMethods();
    const { setFocusedNode } = useViewerStoreMethods();
    const loopControlRef = useRef();
    const [maxLoopsInvalid, setMaxLoopsInvalid] = useState(false);
    const maxLoopsEditorRef = useRef();

    useEffect(
        () =>
            setLoopControl(state => {
                if (!loopControlRef.current) {
                    let curSpec = parameter?.value;
                    if (!curSpec) {
                        const controlledNodes = flow.flowGraph.nodes.filter(n => n.flags?.loopControlledNode).map(n => ({ nodeID: n.id }));
                        curSpec = { ...cloneDeep(initSpec), controlledNodes };
                    }
                    console.log("initializing loopControl state", loopControl);
                    state.maxLoops = curSpec.maxLoops;
                    state.controlledNodes = curSpec.controlledNodes;
                    loopControlRef.current = cloneDeep(curSpec);
                }
            }),
        [step]
    );

    useEffect(() => {
        return () => {
            // unmount during configure & selected node dropped => effective blur so save current loopControl to parameter value
            console.log(useViewerStore.getState().viewer.focusedNode);
            console.log(focusedNode);

            if (
                mode === "configuring" &&
                !useViewerStore.getState().viewer.focusedNode &&
                !deepCompare(loopControlRef.current, parameter?.value)
            ) {
                setParameter(paramUI.pathName, new LoopControlValue(loopControlRef.current, parameter?.locked));
            }
        };
    }, [parameter, paramUI, loopControl, focusedNode]); // [loopControl, parameter]);

    const checkMaxLoopsNumber = e => {
        setMaxLoopsInvalid(Number.isNaN(Number(e.target.value)) && e.target.value !== paramUI.defaultValue?.value);
    };

    const onSelectChoice = (choice, e) => {
        if (choice.runPreview) {
            flowgraphEngine.runPreview();
        } else {
            setParameter(paramUI.pathName, new MatchSpecsValue(choice.edgeIndex, false, choice.label));
            const nodeID = flow.currentNodeID;
            const edgeID = flow.flowGraph.nodes[nodeID]?.exits[choice.edgeIndex].edgeID;
            if (edgeID !== undefined) setEdgeHighlight(nodeID, edgeID, false);
        }
    };

    const duplicateChoice = i => {
        // update loopControl.choices paramSpec
        let choicesUpdated = [...loopControl.choices];
        choicesUpdated.splice(i + 1, 0, cloneDeep(loopControl.choices[i]));
        if (loopControl.mode === "matchAndBranch") {
            // handle matchAndBranch updates
            choicesUpdated = choicesUpdated.map((b, i) => ({ ...b, edgeIndex: i }));
            // update node exit details
            const updatedExits = [...node.exits];
            updatedExits.splice(i + 1, 0, { ...node.exits[i], edgeID: null, label: `${node.exits[i]} copy` });
            updateExits(flow.currentNodeID, updatedExits);
        }
        setLoopControl(state => {
            state.choices = choicesUpdated;
        });
        loopControlRef.current = { ...loopControlRef.current, choices: choicesUpdated };
    };

    const deleteChoice = i => {
        if (loopControl.choices.length > 1) {
            // update loopControl.choices paramSpec
            let choicesUpdated = [...loopControl.choices];
            choicesUpdated.splice(i, 1);
            if (loopControl.mode === "matchAndBranch") {
                choicesUpdated = choicesUpdated.map((b, i) => ({ ...b, edgeIndex: i }));
                // update node exit details
                const updatedExits = [...node.exits];
                updatedExits.splice(i, 1);
                updateExits(flow.currentNodeID, updatedExits);
            }
            setLoopControl(state => {
                state.choices = choicesUpdated;
            });
            loopControlRef.current = { ...loopControlRef.current, choices: choicesUpdated };
        }
    };

    const editDone = () => {
        setParameter(paramUI.pathName, new MatchSpecsValue(loopControl, paramUI.locked));
        if (flow.runState.startsWith("configuring")) setFocusedNode(null);
    };

    const setProp = (e, prop, choice, choiceIndex) => {
        const value = e.target.value.trim();
        console.log("setProp", prop, value);
        if (prop === "pattern") {
            // ensure valid regexp first
            try {
                const m = value.match(/^\/(.*?)\/([igm]*)/); // allow options literal regexps
                const patString = m ? m[1] : value;
                const flags = m ? m[2] : undefined;
                new RegExp(patString, flags);
                setInvalid(invalid => {
                    invalid[choiceIndex] = false;
                });
            } catch (e) {
                setInvalid(invalid => {
                    invalid[choiceIndex] = true;
                });
            }
        }
        if (!invalid[choiceIndex]) {
            setLoopControl(state => {
                state.choices[choiceIndex][prop] = value;
            });
            const choicesUpdated = loopControlRef.current.choices;
            choicesUpdated[choiceIndex][prop] = e.target.value;
            loopControlRef.current = { ...loopControlRef.current, choices: choicesUpdated };
        }
    };

    const EditorForMode = useMemo(
        () =>
            ({
                matchAndSelect: MatchAndSelect,
                matchAndBranch: MatchAndBranch,
                matchAndMessage: MatchAndMessage,
                matchAndButtons: MatchAndPrompt,
                promptButtons: PromptButtons,
            })[paramUI.mode],
        [paramUI.mode]
    );

    const title = useMemo(
        () =>
            ({
                matchAndSelect: "LoopControl & select",
                matchAndBranch: "LoopControl & branch",
                matchAndMessage: "LoopControl & message",
                matchAndButtons: "LoopControl & prompt buttons",
                promptButtons: "Prompt buttons",
            })[loopControl.mode],
        [loopControl.mode]
    );

    return mode === "configuring" ? (
        <UI.FieldBox title={title} setLocked={setLocked} locked={paramUI.locked} style={{ display: "flex", flexDirection: "column" }}>
            <MaxLoops>
                <span>Max. loops: </span>
                <NumberEditor
                    ref={maxLoopsEditorRef}
                    disabled={disabled}
                    $invalid={invalid}
                    defaultValue={number}
                    minRows={1}
                    onChange={checkMaxLoopsNumber}
                    onBlur={setMaxLoopsNumberParam}
                />
            </MaxLoops>
            <ControlledNodesTable>
                <Header>
                    <ColumnLabels>
                        {columnLabels.map(label => (
                            <ColumnLabel key={label}>{label}</ColumnLabel>
                        ))}
                    </ColumnLabels>
                </Header>
                <NodesTableBody>
                    {loopControl.controlledNodes.map(cn => (
                        <ControlledNode>
                            <Indent>indent</Indent>
                            <NodeName>{cn.nodeName}</NodeName>
                            <EndOfLoopMode>eolMode</EndOfLoopMode>
                            <BatchCount>batchCount</BatchCount>
                        </ControlledNode>
                    ))}
                </NodesTableBody>
            </ControlledNodesTable>
        </UI.FieldBox>
    ) : (
        mode === "historical" && (
            <UI.FieldBox title={title} style={{ display: "flex", flexDirection: "column", transform: "scale (0.9)" }}>
                <ControlledNodesTable>
                    <Header>
                        <ColumnLabels>
                            {columnLabels.map(label => (
                                <ColumnLabel key={label}>{label}</ColumnLabel>
                            ))}
                        </ColumnLabels>
                    </Header>
                    <NodesTableBody>
                        {loopControl.controlledNodes.map(cn => (
                            <ControlledNode>
                                <Indent>indent</Indent>
                                <NodeName>{cn.nodeName}</NodeName>
                                <EndOfLoopMode>eolMode</EndOfLoopMode>
                                <BatchCount>batchCount</BatchCount>
                            </ControlledNode>
                        ))}
                    </NodesTableBody>
                </ControlledNodesTable>
            </UI.FieldBox>
        )
    );
};

const ChoicesTable = styled.table`
    border-collapse: collapse;
    border: thin solid #e0e0e0;
`;

const Header = styled.thead`
    th {
        border: thin solid #efefef;
        text-align: left;
        padding: 3px 6px;
        font-size: 11px;
    }
`;

const ColumnLabels = styled.tr``;

const ColumnLabel = styled.th`
    font-size: 10px;
    text-align: center;
`;

const Choices = styled.tbody`
    textarea {
        ${props => props.disabled && `background-color: ${props.mode === "historical" ? "#f1f4f3" : "#fcfcfc"};`}
        ${props => props.mode === "historical" && "height: 18px;"}
    }
    tr {
        ${props => props.mode === "historical" && "margin: 0; height: 90%"}
    }
`;

const ChoiceButton = styled(SimpleButton)`
    width: 100%;
    min-width: 106px;
    font-weight: 400;
`;

const Choice = styled.tr`
    margin: 4px 0;
    td {
        padding: 0 5px;
    }
`;

const Tool = styled.td`
    padding: 5px 3px 5px 0px !important;
    cursor: pointer;
    ${props => props.hidden && "visbility: hidden;"}
`;

const ChoiceButtonLabel = styled(TextEditor)`
    padding: 4px 12px;
    margin-right: 7px;
    border: thin solid #cbcbcb;
    border-radius: 5px;
    font-weight: 600;
    box-shadow:
        rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
`;

const MatchTextEditor = styled(TextEditor)`
    width: 220px;
`;

const Pattern = styled(MatchTextEditor)`
    border: thin solid lightgray;
`;

const OutputText = styled(MatchTextEditor)`
    width: 250px;
`;

const DefaultChoice = styled(OutputText)``;

const LabelText = styled(ChoiceButtonLabel)`
    text-align: center;
`;

const MessageText = styled(MatchTextEditor)``;

const PromptText = styled(MatchTextEditor)`
    width: 250px;
`;

const EditDoneButton = styled.div`
    width: fit-content;
    align-self: center;
    padding: 4px 17px;
    border: thin solid #cbcbcb;
    border-radius: 5px;
    box-shadow:
        rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    cursor: pointer;

    :hover {
        box-shadow:
            0 3px 1px -2px rgb(0 0 0 / 20%),
            0 2px 2px 0 rgb(0 0 0 / 14%),
            0 1px 5px 0 rgb(0 0 0 / 12%);
    }
`;

const MaxLoops = styled.div`
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
`;

export default LoopControl;
