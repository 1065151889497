import React, { useRef, useState } from "react";
import styled from "styled-components/macro";

import {
    useFlowGraphStore,
    useFlowGraphStoreMethods,
    useParameter,
    useParameterDisabledCheck,
} from "@mirinae/hyperflow/modules/stores/flowgraph";
import UI from "@mirinae/hyperflow/components/ui/widgets";
import { CounterValue } from "@mirinae/classes/DataValue";
import { ClosedNumberEditor } from "@hyperflow/components/hyperflow/parameters/NumberEntry";

const Counter = ({ step, paramUI, setLocked }) => {
    const { setParameter, closeParamUI, setInputs } = useFlowGraphStoreMethods();
    const parameter = useParameter(step, paramUI.pathName);
    const disabled = useParameterDisabledCheck(paramUI);
    const [invalid, setInvalid] = useState(false);
    const spinnerRef = useRef();

    const count = parameter?.value || paramUI.defaultValue?.value;

    const setCounterParam = e => {
        if (!invalid) {
            const value =
                !e.target.value || e.target.value === paramUI.defaultValue?.value ? paramUI.defaultValue?.value : Number(e.target.value);
            setParameter(paramUI.pathName, new CounterValue(value, paramUI.locked, paramUI.label));
            if (paramUI.locked) {
                closeParamUI(paramUI, true);
            }
        }
    };

    const counterChanged = e => {
        const count = Number(e.target.value);
        const isInValid = Number.isNaN(count) && e.target.value !== paramUI.defaultValue?.value;
        setInvalid(isInValid);
    };

    return (
        <UI.FieldBox
            title={paramUI.label}
            setLocked={setLocked}
            locked={paramUI.locked}
            accept={!paramUI.closed && count && setCounterParam}
        >
            {paramUI.closed ? (
                <ClosedNumberEditor style={{ marginRight: 20 }}>{parameter.displayValue()}</ClosedNumberEditor>
            ) : (
                <CountSpinner
                    type="number"
                    min={paramUI.minValue || 0}
                    max={paramUI.maxValue || 100}
                    step={paramUI.stepValue || 1}
                    ref={spinnerRef}
                    disabled={disabled}
                    $invalid={invalid}
                    defaultValue={count}
                    minRows={1}
                    onChange={counterChanged}
                    onBlur={setCounterParam}
                />
            )}
        </UI.FieldBox>
    );
};

export const CountSpinner = styled.input`
    border: none;
    // margin-left: 25px;
    font-family:
        DM Sans,
        serif;
    font-size: 14px;
    // letter-spacing: 0.4px;
    text-align: right;
    padding: 5px 7px;
    margin-bottom: -3px;
    resize: none;
    min-width: 120px;
    width: 100%;
    box-sizing: border-box;
    ${props => props.$invalid && "color: red;"};

    :focus {
        outline: none;
    }
`;

export default Counter;
